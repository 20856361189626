import React from 'react';

function Legal() {
  return (
    <div style={{
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'system-ui, -apple-system, sans-serif',
      color: '#333'
    }}>
      <h1 style={{ fontSize: '2rem', marginBottom: '2rem' }}>
        Terms of Service &amp; Privacy Policy
      </h1>
      
      <div style={{ marginBottom: '4rem' }}>
        {/* Header Dates */}
        <div style={{ 
          backgroundColor: '#f5f5f5',
          padding: '1rem',
          borderRadius: '8px',
          marginBottom: '2rem'
        }}>
          <p><strong>Effective Date:</strong> February 19th, 2025</p>
          <p><strong>Last Updated:</strong> February 19th, 2025</p>
        </div>

        {/* Terms of Service Section */}
        <section style={{ marginBottom: '3rem' }}>
          <h2 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>Terms of Service</h2>
          <p>
            Welcome to Onda, an application designed to connect street performers, entertainers, and event organizers with users who wish to discover and attend live events. Onda is owned and operated by 4Frnt Education LLC (“Company,” “we,” “us,” or “our”). By accessing or using Onda, you agree to be bound by these Terms of Service (“Terms”), which govern your use of our application and services.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            1. Acceptance of Terms
          </h3>
          <p>
            By accessing, browsing, or using Onda, you agree to comply with and be bound by these Terms and our Privacy Policy. If you do not agree with any portion of these Terms, please do not use our services. Continued access or use of Onda after changes to these Terms will constitute your acceptance of such changes.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            2. User Accounts &amp; Eligibility
          </h3>
          <p>
            <strong>Eligibility:</strong> You must be at least 16 years old to use Onda. Users under the age of 18 must have the explicit permission of a parent or legal guardian.
          </p>
          <p>
            <strong>Account Creation &amp; Security:</strong> When creating an account, you agree to provide accurate, current, and complete information. To help ensure community safety, all users must verify their identity via email or phone number. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Any breach or unauthorized use must be reported immediately to our support team.
          </p>
          <p>
            <strong>Account Suspension or Termination:</strong> We reserve the right to suspend or terminate accounts at our sole discretion if we determine that a user has violated these Terms, provided false information during registration, or engaged in harmful behavior on the platform.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            3. User-Generated Content and Moderation
          </h3>
          <p>
            <strong>Content Guidelines:</strong>
          </p>
          <ul>
            <li>
              <strong>Prohibited Material:</strong> Users must not post images containing explicit material (including nudity or sexual content), hate speech, defamatory content, or explicit text that violates community standards.
            </li>
            <li>
              <strong>Misleading Information:</strong> Users must refrain from posting false or misleading content. Such violations may lead to suspension or ban, with harmful or dangerous content resulting in immediate removal and banning.
            </li>
            <li>
              <strong>Accuracy and Integrity:</strong> Users are responsible for the veracity of the content they submit, including event details, performer information, and related links. Content that misleads or endangers others may be removed without prior notice.
            </li>
          </ul>
          <p>
            <strong>Content Moderation and Reporting:</strong> Onda reserves the right to review, edit, remove, or block any user-generated content that violates these Terms or is deemed inappropriate at our sole discretion.
          </p>
          <p>
            <strong>Reporting Mechanism:</strong> Users can report content or other users by using the “Report Event” or “Report User” features available within the app. All reports will be reviewed, and disputes related to moderation decisions can be addressed via email at [Insert Contact Email].
          </p>
          <p>
            <strong>Dispute Process:</strong> When a moderation dispute arises, users should contact us via email. We will review the matter and provide a response within a reasonable timeframe, though final decisions regarding content removal rest with Onda.
          </p>
          <p>
            <strong>License Grant:</strong> While all submitted content remains the property of the user, by uploading content (images, event details, text, or links) you grant Onda a worldwide, non-exclusive, royalty-free, perpetual license to store, reproduce, modify, display, distribute, and use such content in connection with the operation and promotion of the app.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            4. Event Information, Third-Party Links, and QR Code Use
          </h3>
          <p>
            <strong>Event Accuracy &amp; Third-Party Links:</strong>
          </p>
          <ul>
            <li>
              <strong>No Verification:</strong> Onda does not verify or endorse the accuracy, completeness, or legitimacy of event details, performer information, or any third-party content.
            </li>
            <li>
              <strong>User Responsibility:</strong> Users are solely responsible for verifying the authenticity of any event or information before attending or interacting with performers.
            </li>
          </ul>
          <p>
            <strong>External Links:</strong> Any links, QR codes, or external references provided through the app are not under our control. Onda is not liable for the content or safety of third-party sites or materials accessed via these links.
          </p>
          <p>
            <strong>QR Codes:</strong>
          </p>
          <ul>
            <li>
              <strong>Onda-Generated Codes:</strong> QR codes generated directly by Onda are designed to be secure.
            </li>
            <li>
              <strong>Manipulated or Fraudulent Codes:</strong> Users are cautioned that QR codes may be manipulated or altered (e.g., via Photoshop) to mimic legitimate codes. Onda disclaims any liability for harm or fraudulent activities resulting from the use of manipulated codes.
            </li>
          </ul>
          <p>
            <strong>Physical Interactions and In-Person Events:</strong>
          </p>
          <ul>
            <li>
              <strong>User Discretion:</strong> Any physical interactions or meetings arranged through the app are solely between users. Onda does not endorse or guarantee the safety, accuracy, or legitimacy of any events.
            </li>
            <li>
              <strong>No Liability:</strong> By attending any event, you acknowledge that you are doing so at your own risk. Onda disclaims all liability for any injury, loss, or damage resulting from attending events or engaging in physical interactions.
            </li>
          </ul>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            5. Location Services and Notifications
          </h3>
          <p>
            <strong>Use of Location Data:</strong> Onda uses location services to help you find events near you and to send you notifications about nearby events. Your location data is used solely for these purposes and to enhance your overall experience on the app.
          </p>
          <p>
            <strong>In-App Streetmoji Game:</strong> The in-app Streetmoji game also utilizes location data to send notifications and provide location-based interactions within the game. Onda takes reasonable measures to ensure that all location-based features function securely; however, users are responsible for reviewing their device settings and managing their location permissions.
          </p>
          <p>
            <strong>User Consent:</strong> By enabling location services on Onda, you consent to our use of your location data as described in our Privacy Policy and these Terms.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            6. Intellectual Property &amp; Branding
          </h3>
          <p>
            <strong>Onda’s Intellectual Property:</strong> Users are prohibited from copying, reproducing, distributing, or creating derivative works of our intellectual property without express written permission.
          </p>
          <p>
            <strong>User-Generated Content:</strong> Although users retain ownership of their submitted content, by posting content on Onda you grant us the right to use, display, and distribute it in connection with our services.
          </p>
          <p>
            <strong>Protection Measures:</strong> While we take steps to protect our brand and intellectual property, we acknowledge that no system is foolproof. Unauthorized use of our branding or IP will be subject to legal action to the fullest extent permitted by law.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            7. Prohibited Conduct
          </h3>
          <p>
            When using Onda, you agree to refrain from:
          </p>
          <ul>
            <li>Posting any content that is explicit, defamatory, misleading, hateful, or otherwise harmful.</li>
            <li>Engaging in harassment, fraud, unauthorized commercial activities, or any behavior that interferes with the operation or security of the app.</li>
            <li>Attempting to gain unauthorized access to Onda’s systems or data.</li>
            <li>Distributing viruses, malware, or any other harmful software.</li>
            <li>Any activity that could reasonably be expected to harm, disrupt, or infringe upon the rights of others.</li>
          </ul>
          <p>
            Violation of these terms may result in immediate suspension or termination of your account without notice.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            8. Data Privacy and Security
          </h3>
          <p>
            <strong>Privacy Policy Reference:</strong> All practices regarding the collection, use, and sharing of personal data are detailed in our Privacy Policy, which is hereby incorporated by reference into these Terms.
          </p>
          <p>
            <strong>Security Measures:</strong> We employ industry-standard security measures to protect your data; however, absolute security cannot be guaranteed. In the event of a data breach or unauthorized access, Onda is not liable for any resulting damages.
          </p>
          <p>
            <strong>User Responsibility:</strong> You are responsible for safeguarding your account information and must promptly notify us of any suspected unauthorized access or breach.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            9. Dispute Resolution and Governing Law
          </h3>
          <p>
            <strong>Informal Resolution:</strong> We encourage users to first attempt to resolve any disputes informally by contacting us via <a href="mailto:rodrigo@onda-st.com" style={{ color: '#1651D4' }}>rodrigo@onda-st.com</a>. We will make every effort to address concerns in a timely manner.
          </p>
          <p>
            <strong>Binding Arbitration:</strong> If a dispute cannot be resolved informally, you agree that any controversy or claim arising out of or relating to these Terms or your use of Onda will be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration will be held in Miami Dade County unless otherwise agreed upon by the parties, and the arbitrator’s decision will be final and binding.
          </p>
          <p>
            <strong>Small Claims:</strong> If applicable, disputes may be brought in small claims court, subject to applicable law, as an alternative to arbitration.
          </p>
          <p>
            <strong>Governing Law:</strong> These Terms are governed by and construed in accordance with the laws of the State of Florida. Any disputes that cannot be resolved through arbitration or small claims will be subject to the jurisdiction of state or federal courts located in Florida.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            10. Limitation of Liability and Disclaimers
          </h3>
          <p>
            <strong>AS IS Basis:</strong> Onda and all associated services are provided on an “AS IS” and “AS AVAILABLE” basis, without any warranties, express or implied.
          </p>
          <p>
            <strong>No Warranty on Content:</strong> We make no warranty regarding the accuracy, reliability, or availability of any content, events, or third-party materials provided through the app.
          </p>
          <p>
            <strong>Limitation of Liability:</strong> To the maximum extent permitted by applicable law, 4Frnt Education LLC, its officers, directors, employees, agents, and affiliates shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the app or inability to use the app. This limitation applies to damages arising from loss of data, personal injury, financial loss, or any disputes among users.
          </p>
          <p>
            <strong>User Assumption of Risk:</strong> By using Onda, you acknowledge that you are solely responsible for any risks associated with attending events or engaging in interactions with other users, and you expressly assume all risks related to such activities.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            11. Changes to These Terms
          </h3>
          <p>
            <strong>Amendments and Updates:</strong> We reserve the right to modify or update these Terms at any time. Significant changes will be communicated to users via email, and the revised Terms will be posted on the app. If required by law, your continued use of the app may be taken as automatic consent to the updated terms.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            12. Entire Agreement
          </h3>
          <p>
            These Terms, together with our Privacy Policy, constitute the entire agreement between you and 4Frnt Education LLC regarding your use of Onda. Any failure by us to enforce any provision of these Terms shall not constitute a waiver of our right to do so in the future.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '2rem' }}>
            13. Contact Information
          </h3>
          <p>
            For any questions, disputes, or concerns regarding these Terms or the operation of Onda, please contact us at:
          </p>
          <p>
            Email: <a href="mailto:rodrigo@onda-st.com" style={{ color: '#1651D4' }}>rodrigo@onda-st.com</a>
          </p>
        </section>

        {/* Privacy Policy Section */}
        <section>
          <h2 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>Privacy Policy</h2>
          <div style={{ 
            backgroundColor: '#f5f5f5',
            padding: '1rem',
            borderRadius: '8px',
            marginBottom: '2rem'
          }}>
            <p><strong>Effective Date:</strong> [Insert Date]</p>
            <p><strong>Last Updated:</strong> [Insert Date]</p>
          </div>
          <p>
            This Privacy Policy explains how Onda ("we," "our," or "us") collects, uses, and safeguards your personal information when you use our mobile application and related services (collectively, the “App”). By accessing or using Onda, you consent to the practices described in this Privacy Policy.
          </p>
          
          <h3 style={{ fontSize: '1.2rem', marginTop: '2rem' }}>1. Information We Collect</h3>
          <h4 style={{ marginTop: '1rem' }}>Personal Information</h4>
          <p>
            <strong>General Users:</strong> Collected: First name, last name, handle, email address, phone number.
          </p>
          <p>
            <strong>Performers/Entertainers:</strong> Collected: First name, last name, handle, email address, phone number, profile picture, links (e.g., social media, websites), and description/bio.
          </p>
          <h4 style={{ marginTop: '1rem' }}>Device and Usage Information</h4>
          <p>
            <strong>Device Identifiers:</strong> We may collect unique device identifiers and other technical information (such as IP addresses) to help us diagnose problems, administer the App, and manage user sessions.
          </p>
          <p>
            <strong>Usage Patterns:</strong> Information about how you interact with the App, including screen views, clicks, and other usage data, is monitored to improve our services.
          </p>
          <h4 style={{ marginTop: '1rem' }}>Location Data</h4>
          <p>
            <strong>User Location:</strong> We collect precise coordinate data to facilitate queries (e.g., finding nearby events) and to send notifications. <strong>Retention:</strong> For users, location data is used for query purposes only and is not stored permanently.
          </p>
          <p>
            <strong>Event Location Data:</strong> We permanently store location data provided for events to ensure that event information remains accurate and useful for discovery.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginTop: '2rem' }}>2. How We Use Your Information</h3>
          <p>
            <strong>Service Functionality:</strong> Personal and usage data is essential for account creation, verifying identity, and ensuring that the App functions correctly. Users cannot opt out of data collection, as the App’s functionality depends on it.
          </p>
          <p>
            <strong>Enhancing User Experience:</strong> Location data is used to find events near you and to send notifications about local events or features such as our in-app streetmoji game.
          </p>
          <p>
            <strong>Communications:</strong> We may use your email address and phone number to send promotional emails or notifications about updates, events, or changes to the App. Users who find themselves on our promotional list may opt out of these communications at any time.
          </p>
          <p>
            <strong>Security and Diagnostics:</strong> Device identifiers and usage data are used to maintain app security, analyze trends, diagnose problems, and help us improve our services.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginTop: '2rem' }}>3. Data Sharing and Disclosure</h3>
          <p>
            <strong>No Third-Party Sharing:</strong> We do not sell, trade, or otherwise share your personal data with third parties.
          </p>
          <p>
            <strong>Legal Disclosures:</strong> We may disclose your information if required to do so by law, in response to a valid subpoena, court order, or other legal process.
          </p>
          <p>
            <strong>Data Access for Law Enforcement:</strong> In the event of a lawful request, we will provide access to user data as required by the governing laws and legal procedures.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginTop: '2rem' }}>4. Data Storage and Retention</h3>
          <p>
            <strong>Indefinite Storage:</strong> All user data, including personal information, device data, usage patterns, and event location data, is stored indefinitely.
          </p>
          <p>
            <strong>Retention Policy:</strong> We do not differentiate between types of data for retention purposes. All data is maintained until you request deletion or our services are otherwise terminated.
          </p>
          <p>
            <strong>Security Measures:</strong> User data is protected by Firebase security rules and other industry-standard security practices. Despite these measures, we cannot guarantee absolute security.
          </p>
          <p>
            <strong>Data Breach Notification:</strong> In the event of a data breach or unauthorized access, we will notify affected users promptly via email.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginTop: '2rem' }}>5. Your Rights and Choices</h3>
          <p>
            <strong>Consent:</strong> By signing up for and using Onda, you expressly consent to our collection and use of your data as described in this Privacy Policy.
          </p>
          <p>
            <strong>Account Deletion and Updates:</strong> You may request to delete or update your personal information by contacting us at [Insert Contact Email]. Please note that deletion requests will be handled in accordance with applicable laws and our data retention policies.
          </p>
          <p>
            <strong>Opt-Out of Communications:</strong> You may opt out of receiving promotional emails or marketing communications by following the unsubscribe instructions provided in each email.
          </p>
          <p>
            <strong>Eligibility:</strong> Users must be at least 16 years old to use the App. Parental consent is required for users below the age of majority, as applicable by law.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginTop: '2rem' }}>6. Additional Protections</h3>
          <p>
            <strong>Cookies and Similar Technologies:</strong> We may use cookies and other tracking technologies to enhance your experience, analyze usage, and improve our services. Details regarding these technologies are available in our Cookie Policy, which is incorporated by reference into this Privacy Policy.
          </p>
          <p>
            <strong>Changes in Data Collection Practices:</strong> Should there be any material changes to our data collection or usage practices, we will notify you by email and update this Privacy Policy accordingly.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginTop: '2rem' }}>7. Updates to This Privacy Policy</h3>
          <p>
            <strong>Notification of Changes:</strong> We will notify you of any significant changes to this Privacy Policy via email, in-app notifications, or both. Your continued use of the App after such changes constitutes your acceptance of the updated Privacy Policy.
          </p>
          <p>
            <strong>Review Periodically:</strong> We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
          </p>

          <h3 style={{ fontSize: '1.2rem', marginTop: '2rem' }}>8. Contact Information</h3>
          <p>
            If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:
          </p>
          <p>
            Email: <a href="mailto:rodrigo@onda-st.com" style={{ color: '#1651D4' }}>rodrigo@onda-st.com</a>
          </p>
        </section>
      </div>

      <footer style={{
        borderTop: '1px solid #eee',
        paddingTop: '2rem',
        marginTop: '4rem',
        textAlign: 'center',
        color: '#666'
      }}>
        <p>For any questions about these terms, please contact us at:</p>
        <p>
          <a href="mailto:rodrigo@onda-st.com" style={{ color: '#1651D4' }}>rodrigo@onda-st.com</a>
        </p>
      </footer>
    </div>
  );
}

export default Legal;
