class RateLimiter {
  constructor(maxRequests, timeWindow) {
    this.maxRequests = maxRequests;
    this.timeWindow = timeWindow; // in milliseconds
    this.requests = [];
  }

  tryRequest() {
    const now = Date.now();
    
    // Remove old requests outside the time window
    this.requests = this.requests.filter(time => time > now - this.timeWindow);
    
    // Check if we're under the limit
    if (this.requests.length < this.maxRequests) {
      this.requests.push(now);
      return true;
    }
    
    return false;
  }
}

// Create specific limiters for different operations
const readLimiter = new RateLimiter(30, 1000); // 30 reads per second
const writeLimiter = new RateLimiter(10, 1000); // 10 writes per second

// Stricter limits for Streetmoji
const streetmojiReadLimiter = new RateLimiter(5, 1000); // 5 reads per second
const streetmojiWriteLimiter = new RateLimiter(2, 1000); // 2 writes per second

// Wrapper for Firebase operations
export const rateLimit = async (operation, isStreetmoji = false) => {
  const limiter = isStreetmoji ? streetmojiReadLimiter : readLimiter;
  
  if (!limiter.tryRequest()) {
    throw new Error('Rate limit exceeded. Please try again later.');
  }
  
  return operation();
};

export const rateLimitWrite = async (operation, isStreetmoji = false) => {
  const limiter = isStreetmoji ? streetmojiWriteLimiter : writeLimiter;
  
  if (!limiter.tryRequest()) {
    throw new Error('Rate limit exceeded. Please try again later.');
  }
  
  return operation();
}; 