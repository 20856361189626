import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, useLoadScript, Circle, Marker, OverlayView } from '@react-google-maps/api';
import { collection, query, where, getDocs, Timestamp, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  ChevronLeft, 
  ChevronRight, 
  CircleParking, 
  Fish, 
  Search, 
  Umbrella,
  Heart,
  MapPin,
  Trophy,
  Link,
  Users,
  Star
} from 'lucide-react';

// Define libraries array outside component
const libraries = ['places', 'geometry'];

function Home() {
  const navigate = useNavigate();
  const [userLocation, setUserLocation] = useState({ lat: 51.5074, lng: -0.1278 });
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480); // Phone breakpoint
  const [showEventCount, setShowEventCount] = useState(false);
  const [eventCountTrigger, setEventCountTrigger] = useState(0);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCp2gtMmzdT8agxpsc6qyxnpJIpnCS1dwU",
    libraries // Use the static libraries array
  });

  // Function to calculate bounding box for 5-mile radius
  const calculateBoundingBox = (lat, lng, distance) => {
    const milesPerLat = 69; // Approximate miles per degree latitude
    const milesPerLng = Math.cos(lat * Math.PI / 180) * 69;
    
    return {
      minLat: lat - (distance / milesPerLat),
      maxLat: lat + (distance / milesPerLat),
      minLng: lng - (distance / milesPerLng),
      maxLng: lng + (distance / milesPerLng)
    };
  };

  // Fetch events based on location
  const fetchNearbyEvents = async (lat, lng) => {
    const bounds = calculateBoundingBox(lat, lng, 5);
    const currentTime = Timestamp.now();

    console.log('=== Query Debug ===');
    console.log('1. User Location:', { lat, lng });
    console.log('2. Calculated Bounds:', bounds);
    console.log('3. Current Time:', currentTime.toDate());

    try {
      const eventsRef = collection(db, 'events');
      
      // First, let's get ALL documents to see if there are any
      console.log('4a. Querying ALL documents...');
      const allDocsSnapshot = await getDocs(eventsRef);
      console.log('4b. Total documents in collection:', allDocsSnapshot.size);
      
      // Log the first document to see its structure
      if (allDocsSnapshot.size > 0) {
        const firstDoc = allDocsSnapshot.docs[0];
        console.log('4c. Sample document structure:', {
          id: firstDoc.id,
          data: firstDoc.data(),
          endTimeType: firstDoc.data().endTime?.constructor.name,
        });
      }

      // Now try the filtered query
      const q = query(
        eventsRef,
        where('endTime', '>', currentTime)
      );

      console.log('5. Querying with endTime filter...');
      const querySnapshot = await getDocs(q);
      console.log('6. Filtered Results:', querySnapshot.size, 'documents found');

      const eventsData = [];

      querySnapshot.forEach((doc) => {
        const eventData = doc.data();
        eventData.id = doc.id;

        console.log('7. Checking location bounds for event:', eventData.name);
        console.log('   Event location:', {
          lat: eventData.latitude,
          lng: eventData.longitude
        });
        console.log('   Bounds check:', {
          latInBounds: eventData.latitude >= bounds.minLat && eventData.latitude <= bounds.maxLat,
          lngInBounds: eventData.longitude >= bounds.minLng && eventData.longitude <= bounds.maxLng
        });

        if (eventData.latitude >= bounds.minLat && 
            eventData.latitude <= bounds.maxLat &&
            eventData.longitude >= bounds.minLng && 
            eventData.longitude <= bounds.maxLng) {
          console.log('   ✓ Event within bounds');
          eventsData.push(eventData);
        } else {
          console.log('   ✗ Event outside bounds');
        }
      });

      console.log('8. Final filtered events:', eventsData.length);
      console.log('=== End Debug ===');

      setEvents(eventsData);
    } catch (error) {
      console.error("Error fetching events:", error);
      console.error("Error details:", error.message);
    }
  };

  useEffect(() => {
    if (!isLoaded) return; // Only proceed if map is loaded

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setUserLocation(newLocation);
          fetchNearbyEvents(newLocation.lat, newLocation.lng);
        },
        (error) => {
          console.error("Error getting location:", error);
          fetchNearbyEvents(userLocation.lat, userLocation.lng);
        }
      );
    }
  }, [isLoaded]); // Only run when map is loaded

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (showEventCount && isMobile) {
      const timer = setTimeout(() => {
        setShowEventCount(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showEventCount, eventCountTrigger, isMobile]);

  const onMapLoad = useCallback((map) => {
    if (!mapRef) {
      setMapRef(map);
    }
  }, [mapRef]);

  const searchThisArea = async () => {
    if (mapRef) {
      setIsSearching(true);
      const center = mapRef.getCenter();
      await fetchNearbyEvents(center.lat(), center.lng());
      setIsSearching(false);
      if (isMobile) {
        setShowEventCount(true);
        setEventCountTrigger(prev => prev + 1);
      }
    }
  };

  // Format time for display
  const formatTime = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Add this function to format the date
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const weekFromNow = new Date(today);
    weekFromNow.setDate(weekFromNow.getDate() + 7);

    // Check if it's today
    if (date.toDateString() === today.toDateString()) {
      return 'TDY';
    }
    // Check if it's tomorrow
    if (date.toDateString() === tomorrow.toDateString()) {
      return 'TMR';
    }
    // If within a week, show 3-letter day
    if (date < weekFromNow) {
      return date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
    }
    // Otherwise show MM/DD
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
  };

  const readAllEvents = async () => {
    try {
      // Create a test event
      const eventsRef = collection(db, 'events');
      const newEvent = await addDoc(eventsRef, {
        name: "Test Street Performance",
        performerName: "Test Performer",
        startTime: Timestamp.fromDate(new Date(Date.now() + 24 * 60 * 60 * 1000)), // tomorrow
        endTime: Timestamp.fromDate(new Date(Date.now() + 25 * 60 * 60 * 1000)), // tomorrow + 1 hour
        location: "Test Location",
        latitude: 25.7617,
        longitude: -80.1918,
        category: "Music",
        type: "Live Music",
        radius: 100,
        performerId: "test123",
        description: "Test event description"
      });

      console.log("Created test event with ID:", newEvent.id);
      alert("Test event created successfully!");

      // Now read all events to verify
      const snapshot = await getDocs(eventsRef);
      console.log('=== All Events ===');
      console.log('Total Events:', snapshot.size);
      snapshot.forEach((doc) => {
        console.log('Event:', {
          id: doc.id,
          ...doc.data()
        });
      });
    } catch (error) {
      console.error("Error with events:", error);
      alert("Error: " + error.message);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) return;

    const touchEnd = e.touches[0].clientX;
    const diff = touchStart - touchEnd;

    if (Math.abs(diff) > 50) { // minimum swipe distance
      if (diff > 0 && currentEventIndex < events.length - 1) {
        setCurrentEventIndex(prev => prev + 1);
      } else if (diff < 0 && currentEventIndex > 0) {
        setCurrentEventIndex(prev => prev - 1);
      }
      setTouchStart(null);
    }
  };

  const MobileView = () => {
    if (!isLoaded) {
      return (
        <div style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f0f0'
        }}>
          Loading map...
        </div>
      );
    }

    return (
      <div style={{ 
        minHeight: '100vh',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        position: 'relative'
      }}>
            <div onClick={() => navigate('/streetmoji')} style={{
          width: '100%',
          background: '#000000',
          padding: '0px 20px',
          height: '60px',
          position: 'relative'
        }}>
          <div style={{position: 'absolute', width: '100%', height: '2px', background: 'white', top: '10px', left: '-10px' }}></div>
          <h2 style={{
            color: 'white',
            fontSize: isMobile ? '1.4rem' : '1.4rem',
            fontWeight: '500',
            marginTop: '0px',
            lineHeight: '60px',
            width: 'calc(100% - 10px)', 
            textAlign: 'left'
          }}>
            Play StreetMoji, <u>Earn Mojo</u>
          </h2>
          {/* Only show Search icon on mobile */}
          {isMobile ? (
            <Search style={{
              color: 'white', 
              position: 'absolute', 
              top: '18px', 
              right: '50px', 
              padding: '5px', 
              background: 'green', 
              borderRadius: '100px'
            }}
            size={20}/>
          ) : (
            // Show all icons on desktop
            <>
              <Search style={{color: 'white', position: 'absolute', top: '25px', right: '40px', padding: '12px', background: 'green', borderRadius: '100px'}}/>
              <CircleParking style={{ right: '100px', color: 'white', position: 'absolute', top: '25px', padding: '12px', background: 'blue', borderRadius: '100px'}}/>
              <Fish style={{ right: '160px', color: 'white', position: 'absolute', top: '25px', padding: '12px', background: 'red', borderRadius: '100px'}}/>
              <Umbrella style={{ right: '220px', color: 'white', position: 'absolute', top: '25px', padding: '12px', background: 'purple', borderRadius: '100px'}}/>
            </>
          )}
        </div>

        {/* Map Section */}
        <div style={{ 
          height: '70vh',
          position: 'relative'
        }}>
          {/* Top Bar */}
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 10
          }}>
            <img 
              src="/OndaSign.svg" 
              alt="Onda Sign"
              style={{
                height: isMobile ? '40px' : '80px',
                width: 'auto'
              }}
            />
            <div style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'center'
            }}>
              <button
                onClick={searchThisArea}
                style={{
                  backgroundColor: '#1651d4',
                  color: 'white',
                  padding: '6px 12px',
                  borderRadius: '16px',
                  border: 'none',
                  fontSize: '14px'
                }}
              >
                Search area
              </button>
            </div>
          </div>

          {/* Map */}
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '100%'
            }}
            center={userLocation}
            zoom={15}
            options={{
              mapTypeId: 'satellite',
              disableDefaultUI: true,
              zoomControl: true,
              scrollwheel: false  // Disable map zoom on scroll
            }}
            onLoad={onMapLoad}
            onClick={() => setSelectedEvent(null)}
          >
            {/* User Location Marker */}
            <Marker
              position={userLocation}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 8,
                fillColor: '#1651d4',
                fillOpacity: 1,
                strokeColor: '#ffffff',
                strokeWeight: 2,
              }}
            />

            {/* Event Circles and Info Windows */}
            {events.map((event) => (
              <React.Fragment key={event.id}>
                <Circle
                  center={{ lat: event.latitude, lng: event.longitude }}
                  radius={event.radius}
                  options={{
                    fillColor: '#1651d4',
                    fillOpacity: 0.3,
                    strokeColor: '#1651d4',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    clickable: true,
                    zIndex: selectedEvent?.id === event.id ? 2 : 1
                  }}
                  onClick={(e) => {
                    e.stop();
                    setSelectedEvent(event);
                    // Pan map slightly up to show info window better
                    if (mapRef) {
                      const latLng = new window.google.maps.LatLng(event.latitude, event.longitude);
                      mapRef.panTo(latLng);
                    }
                  }}
                />
                {selectedEvent?.id === event.id && (
                  <OverlayView
                    position={{ lat: event.latitude, lng: event.longitude }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div style={{
                      position: 'relative',
                      transform: 'translate(-50%, 20px)',
                      backgroundColor: 'rgba(255, 255, 255, 0.4)',
                      backdropFilter: 'blur(16px)',
                      WebkitBackdropFilter: 'blur(16px)',
                      padding: '15px',
                      borderRadius: '12px',
                      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                      border: '1px solid rgba(255, 255, 255, 0.3)',
                      zIndex: 1000,
                      minWidth: '250px',
                      maxWidth: '350px',
                      marginTop: '10px',
                      color: '#000',
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        marginBottom: '12px'
                      }}>
                        <img 
                          src={event.performerProfilePictureURL} 
                          alt={event.performerName}
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '12px',
                            objectFit: 'cover',
                            border: '2px solid #1651d4'
                          }}
                        />
                        <div>
                          <h3 style={{ 
                            margin: '0 0 4px 0', 
                            color: '#1651d4',
                            fontSize: '18px'
                          }}>
                            {event.name}
                          </h3>
                          <p style={{ 
                            margin: '0',
                            fontSize: '14px',
                            fontWeight: '500'
                          }}>
                            {event.performerName}
                          </p>
                        </div>
                      </div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        margin: '8px 0'
                      }}>
                        <span style={{
                          backgroundColor: '#1651d4',
                          color: 'white',
                          padding: '2px 8px',
                          borderRadius: '12px',
                          fontSize: '12px',
                          fontWeight: 'bold'
                        }}>
                          {formatDate(event.startTime)}
                        </span>
                        <span style={{ fontSize: '14px' }}>
                          {formatTime(event.startTime)} - {formatTime(event.endTime)}
                        </span>
                      </div>
                      <p style={{ 
                        margin: '8px 0', 
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                      }}>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/>
                          <circle cx="12" cy="10" r="3"/>
                        </svg>
                        {event.location}
                      </p>
                      <div style={{
                        display: 'flex',
                        gap: '8px',
                        fontSize: '12px',
                        color: '#666',
                        marginBottom: '8px'
                      }}>
                        <span>{event.genre}</span>
                        •
                        <span>{event.category}</span>
                      </div>
                      <p style={{
                        margin: '0',
                        fontSize: '13px',
                        color: '#444',
                        lineHeight: '1.4'
                      }}>
                        {event.description}
                      </p>
                    </div>
                  </OverlayView>
                )}
              </React.Fragment>
            ))}
          </GoogleMap>

          {/* Add Gradient Overlay */}
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '200px',
            background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, #1651d4 100%)',
            pointerEvents: 'none',
            zIndex: 2
          }} />

          {/* Add Find what's going on text */}
          <div style={{
            position: 'absolute',
            bottom: '40px',
            left: '20px',
            color: 'white',
            fontSize: '2.5rem',
            fontWeight: 'bold',
            lineHeight: '1.2',
            zIndex: 3,
            textShadow: '2px 2px 4px rgba(0,0,0,0.2)'
          }}>
            Find<br/>
            what's going on<br/>
            around you
          </div>
        </div>

        {/* Events List Section */}
        <div style={{
          background: '#1651d4',
          padding: '20px'
        }}>
          <div style={{
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: '1fr'
          }}>
            {events.map((event) => (
              <div
                key={event.id}
                style={{
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  cursor: 'pointer'
                }}
                onClick={() => navigate(`/performance/${event.id}`)}
              >
                <img
                  src={event.imageURL}
                  alt={event.name}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover'
                  }}
                />
                <div style={{
                  padding: '15px',
                  color: 'white'
                }}>
                  <h3 style={{
                    margin: '0 0 8px 0',
                    fontSize: '18px',
                    fontWeight: '600'
                  }}>
                    {event.name}
                  </h3>
                  <p style={{
                    margin: '0',
                    fontSize: '14px',
                    opacity: 0.8
                  }}>
                    {event.location}
                  </p>
                  <div style={{
                    marginTop: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    fontSize: '12px',
                    opacity: 0.7
                  }}>
                    <span>{formatTime(event.startTime)}</span>
                    •
                    <span>{formatDate(event.startTime)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Black Banner Section */}
    

        {/* Info Tiles Section */}
        <div style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '20px',
          background: 'url(/Street.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center 85%',
          backgroundAttachment: 'fixed',
          minHeight: isMobile ? 'auto' : '100vh',
          padding: '20px',
          position: 'relative',
          marginBottom: isMobile ? '-100px' : '-150px'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '20px',
            width: '100%',
            maxWidth: '1400px',
            margin: isMobile ? '40px auto' : '160px auto',
            justifyContent: 'center'
          }}>
            {/* Pedestrians Tile */}
            <div style={{
              flex: 1,
              maxWidth: isMobile ? '100%' : '600px',
              padding: '30px',
              borderRadius: '16px',
              position: 'relative'
            }}>
              {/* Add a semi-transparent overlay */}
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(255, 255, 255, 0.9)',
                borderRadius: '16px'
              }} />
              
              {/* Header Section */}
              <div style={{
                background: '#1651d4',
                margin: '0px ',
                padding: ' 10px 30px ',
                borderRadius: '16px',
                border: '3px solid #1651d4',
                position: 'relative',
                zIndex: 1
              }}>
                {/* Inner white border */}
                <div style={{
                  position: 'absolute',
                  top: '3px',
                  left: '3px',
                  right: '3px',
                  bottom: '3px',
                  border: '4px solid white',
                  borderRadius: '12px 12px',
                  pointerEvents: 'none'
                }} />
                <h3 className="highwayfont" style={{
                  fontSize: isMobile ? '2.5rem' : '4.5rem',
                  margin: 0,
                  color: 'white',
                  position: 'relative'
                }}>
                  For Pedestrians
                </h3>
              </div>

              <ul style={{
                listStyle: 'none',
                padding: 0,
                margin: 0,
                fontSize: '24px',
                marginTop: '60px',
                lineHeight: '2',
                color: '#333',
                position: 'relative',
                zIndex: 1
              }}>
                {[
                  { text: 'Find Events', icon: Search, color: '#FF4136' },
                  { text: 'Declare Events', icon: MapPin, color: '#2ECC40' },
                  { text: 'Follow your favorites', icon: Heart, color: '#B10DC9' },
                  { text: 'Earn Mojo', icon: Trophy, color: '#FF851B' }
                ].map((item, index) => (
                  <li key={index} style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '20px',
                    marginBottom: '20px'
                  }}>
                    <div style={{ 
                      width: '40px', 
                      height: '40px', 
                      background: item.color,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white'
                    }}>
                      <item.icon size={24} strokeWidth={3} />
                    </div>
                    <span style={{ fontWeight: 600 }}>{item.text}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Performers Tile */}
            <div style={{
              flex: 1,
              maxWidth: isMobile ? '100%' : '600px',
              padding: '30px',
              borderRadius: '16px',
              position: 'relative'
            }}>
              {/* Add a semi-transparent overlay */}
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(55, 55, 55, 0.9)',
                borderRadius: '16px'
              }} />
              
              <h3 className="electronic" style={{
                fontSize: isMobile ? '1.8rem' : '2rem',
               marginTop: '0px',
                color: '#FFA500',
                background: 'black',
                padding: '18px 16px 10px 20px',
                width: 'calc(100% - 32px)',
                borderRadius: '8px',
                fontWeight: '500',
                display: 'inline-block',
                position: 'relative',
                zIndex: 1
              }}>
                For Performers
              </h3>
              
              <ul style={{
                listStyle: 'none',
                padding: 0,
                margin: 0,
                fontSize: '24px',
                lineHeight: '2',
                color: 'white',
                position: 'relative',
                zIndex: 1
              }}>
                {[
                  { text: 'Share your links', icon: Link, color: '#0074D9' },
                  { text: 'Connect with others', icon: Users, color: '#FF4136' },
                  { text: 'Be noticed', icon: Star, color: '#FFDC00' }
                ].map((item, index) => (
                  <li key={index} style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '20px',
                    marginBottom: '20px'
                  }}>
                    <div style={{ 
                      width: '40px', 
                      height: '40px', 
                      background: item.color,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white'
                    }}>
                      <item.icon size={24} />
                    </div>
                    <span style={{ fontWeight: 600 }}>{item.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Mobile App Promotion */}
        <div style={{
          background: isMobile ? 
            'linear-gradient(180deg, rgba(22, 82, 212, 0) 0%,  #000000 20%)' : 
            'linear-gradient(180deg, rgba(22, 82, 212, 0) 0%,  #000000 20%)',
          padding: isMobile ? '120px 20px 40px' : '150px 20px 40px',
          color: 'white',
          textAlign: 'center',
          position: 'relative',
          zIndex: 1
        }}>
          <div style={{
            maxWidth: '1200px',
            margin: '0 auto',
            position: 'relative'
          }}>
            <h2 style={{
              fontSize: isMobile ? '1.5rem' : '2.5rem',
              marginBottom: '20px',
              fontWeight: '500'
            }}>
              Get the Full Experience
            </h2>
            <p style={{
              fontSize: isMobile ? '1rem' : '1.2rem',
              marginBottom: '30px',
              color: 'rgba(255,255,255,0.8)',
              maxWidth: '800px',
              margin: '0 auto 30px'
            }}>
              Download the mobile app to unlock all features:
            </p>
            <div style={{
              display: 'grid',
              gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
              gap: isMobile ? '12px' : '20px',
              maxWidth: '900px',
              margin: '0 auto'
            }}>
              {[
                '📊 Check the Leaderboard',
                '🏆 Earn Mojo Points',
                '📍 Declare Events',
                '👥 Follow Performers',
                '🎮 Play Streetmoji Mobile',
                '🌟 Exclusive Features'
              ].map((feature, index) => (
                <div key={index} style={{
                  background: 'rgba(255,255,255,0.1)',
                  padding: isMobile ? '15px' : '20px',
                  borderRadius: '12px',
                  fontSize: isMobile ? '0.9rem' : '1.1rem'
                }}>
                  {feature}
                </div>
              ))}
            </div>
            <img 
              src="/Download.svg" 
              alt="Download App"
              style={{
                marginTop: '40px',
                height: '50px',
                cursor: 'pointer'
              }}
              onClick={() => window.open('your-app-store-link', '_blank')}
            />
          </div>
        </div>
      </div>
    );
  };

  if (loadError) {
    return (
      <div style={{ 
        height: '50vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundColor: '#f0f0f0'
      }}>
        <p>Error loading maps. Please try again later.</p>
      </div>
    );
  }

  return isMobile ? <MobileView /> : (
    <div style={{ 
      height: '100vh', 
      overflowY: 'auto',
      overflowX: 'hidden', // Prevent horizontal scrolling
      display: 'flex', 
      flexDirection: 'column'
    }}>
      <style>
        {`
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
        `}
      </style>
      {/* Map Section */}
      <div style={{ position: 'relative', height: '100vh', minHeight: '100vh' }}>
      <div 
      onClick={() => navigate('/streetmoji')}
      style={{
        width: '100%',
        background: '#000000',
        padding: '0px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
        gap: '20px',
        height: '80px',
        cursor: 'pointer',
        boxSizing: 'border-box'
      }}>
        <div style={{position: 'absolute', width: '100%', height: '4px', background: 'white', top: '10px' }}></div>
        <h2 style={{
          color: 'white',
          fontSize: isMobile ? '.7rem' : '2rem',
          fontWeight: '500',
          width: 'calc(100% - 10px)', 
          textAlign: 'left'
        }}>
          Play StreetMoji, <u>Earn Mojo</u>
        </h2>
        {/* Only show Search icon on mobile */}
        {isMobile ? (
          <Search style={{
            color: 'white', 
            position: 'absolute', 
            top: '25px', 
            right: '40px', 
            padding: '12px', 
            background: 'green', 
            borderRadius: '100px'
          }}
          size={10}/>
        ) : (
          // Show all icons on desktop
          <>
            <Search style={{color: 'white', position: 'absolute', top: '25px', right: '40px', padding: '12px', background: 'green', borderRadius: '100px'}}/>
            <CircleParking style={{ right: '100px', color: 'white', position: 'absolute', top: '25px', padding: '12px', background: 'blue', borderRadius: '100px'}}/>
            <Fish style={{ right: '160px', color: 'white', position: 'absolute', top: '25px', padding: '12px', background: 'red', borderRadius: '100px'}}/>
            <Umbrella style={{ right: '220px', color: 'white', position: 'absolute', top: '25px', padding: '12px', background: 'purple', borderRadius: '100px'}}/>
          </>
        )}
      </div>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '100%'
            }}
            center={userLocation}
            zoom={15}
            options={{
              mapTypeId: 'satellite',
              disableDefaultUI: true,
              zoomControl: true,
              scrollwheel: false  // Disable map zoom on scroll
            }}
            onLoad={onMapLoad}
            onClick={() => setSelectedEvent(null)}
          >
            {/* User Location Marker */}
            <Marker
              position={userLocation}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 8,
                fillColor: '#1651d4',
                fillOpacity: 1,
                strokeColor: '#ffffff',
                strokeWeight: 2,
              }}
            />

            {/* Event Circles and Info Windows */}
            {events.map((event) => (
              <React.Fragment key={event.id}>
                <Circle
                  center={{ lat: event.latitude, lng: event.longitude }}
                  radius={event.radius}
                  options={{
                    fillColor: '#1651d4',
                    fillOpacity: 0.3,
                    strokeColor: '#1651d4',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    clickable: true,
                    zIndex: selectedEvent?.id === event.id ? 2 : 1
                  }}
                  onClick={(e) => {
                    e.stop();
                    setSelectedEvent(event);
                    // Pan map slightly up to show info window better
                    if (mapRef) {
                      const latLng = new window.google.maps.LatLng(event.latitude, event.longitude);
                      mapRef.panTo(latLng);
                    }
                  }}
                />
                {selectedEvent?.id === event.id && (
                  <OverlayView
                    position={{ lat: event.latitude, lng: event.longitude }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div style={{
                      position: 'relative',
                      transform: 'translate(-50%, 20px)',
                      backgroundColor: 'rgba(255, 255, 255, 0.4)',
                      backdropFilter: 'blur(16px)',
                      WebkitBackdropFilter: 'blur(16px)',
                      padding: '15px',
                      borderRadius: '12px',
                      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                      border: '1px solid rgba(255, 255, 255, 0.3)',
                      zIndex: 1000,
                      minWidth: '250px',
                      maxWidth: '350px',
                      marginTop: '10px',
                      color: '#000',
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        marginBottom: '12px'
                      }}>
                        <img 
                          src={event.performerProfilePictureURL} 
                          alt={event.performerName}
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '12px',
                            objectFit: 'cover',
                            border: '2px solid #1651d4'
                          }}
                        />
                        <div>
                          <h3 style={{ 
                            margin: '0 0 4px 0', 
                            color: '#1651d4',
                            fontSize: '18px'
                          }}>
                            {event.name}
                          </h3>
                          <p style={{ 
                            margin: '0',
                            fontSize: '14px',
                            fontWeight: '500'
                          }}>
                            {event.performerName}
                          </p>
                        </div>
                      </div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        margin: '8px 0'
                      }}>
                        <span style={{
                          backgroundColor: '#1651d4',
                          color: 'white',
                          padding: '2px 8px',
                          borderRadius: '12px',
                          fontSize: '12px',
                          fontWeight: 'bold'
                        }}>
                          {formatDate(event.startTime)}
                        </span>
                        <span style={{ fontSize: '14px' }}>
                          {formatTime(event.startTime)} - {formatTime(event.endTime)}
                        </span>
                      </div>
                      <p style={{ 
                        margin: '8px 0', 
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                      }}>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/>
                          <circle cx="12" cy="10" r="3"/>
                        </svg>
                        {event.location}
                      </p>
                      <div style={{
                        display: 'flex',
                        gap: '8px',
                        fontSize: '12px',
                        color: '#666',
                        marginBottom: '8px'
                      }}>
                        <span>{event.genre}</span>
                        •
                        <span>{event.category}</span>
                      </div>
                      <p style={{
                        margin: '0',
                        fontSize: '13px',
                        color: '#444',
                        lineHeight: '1.4'
                      }}>
                        {event.description}
                      </p>
                    </div>
                  </OverlayView>
                )}
              </React.Fragment>
            ))}
          </GoogleMap>
        ) : (
          <div style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f0f0f0'
          }}>
            Loading map...
          </div>
        )}
        
        {/* Gradient Overlay */}
        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '300px',
          background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, #1651d4 100%)',
          pointerEvents: 'none'
        }} />

        {/* Onda Sign - Top Left */}
        <div style={{
          position: 'absolute',
          top: '120px',
          left: '20px',
          zIndex: 10
        }}>
          <img 
            src="/OndaSign.svg" 
            alt="Onda Sign"
            style={{
              height: isMobile ? '40px' : '80px',
              width: 'auto'
            }}
          />
        </div>

          <button
            onClick={searchThisArea}
            disabled={isSearching}
            style={{
              backgroundColor: '#1651d4',
              color: 'white',
              padding: '8px 16px',
              borderRadius: '5px',
              border: 'none',
              position: 'absolute',
              top: '120px',
              right: '20px',
              cursor: isSearching ? 'default' : 'pointer',
              fontSize: '22px',
              fontWeight: 'medium',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              opacity: isSearching ? 0.8 : 1
            }}
          >
            {isSearching ? (
              <>
                <svg 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2"
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                  style={{
                    animation: 'spin 1s linear infinite'
                  }}
                >
                  <circle cx="12" cy="12" r="10"/>
                  <path d="M12 2a10 10 0 0 1 10 10"/>
                </svg>
                Searching...
              </>
            ) : (
              <>
                <svg 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2"
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"/>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"/>
                </svg>
                Search this area
              </>
            )}
          </button>

        {/* Find What's Going on Text - remove events count */}
        <div 
          style={{
            position: 'absolute',
            bottom: '40px',
            left: '40px',
            color: 'white',
            
            textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
            fontSize: 'clamp(2.9rem, 5vw, 4.5rem)',
            fontWeight: 'bold',
            lineHeight: '1.2'
          }}
        >
          Find<br/>
          what's going on<br/>
          around you
        </div>
      </div>

      {/* Events Grid Section */}
      <div style={{
        background: '#1651d4',
        padding: '40px 20px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
        gap: '32px',
        width: '100%',
        boxSizing: 'border-box'
      }}>
        {events.map((event) => (
          <div
            key={event.id}
            style={{
              position: 'relative',
              width: '100%',
              height: '400px',
              cursor: 'pointer',
              borderRadius: '20px',
              overflow: 'visible'
            }}
            onClick={() => navigate(`/performance/${event.id}`)}
          >
            {/* Event Image Background */}
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '20px',
              backgroundImage: `url(${event.imageURL})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }} />

            {/* Info Bar */}
            <div style={{
              position: 'absolute',
              bottom: '15px',
              left: '-15px',
              right: '-15px',
              padding: '10px 24px',
              background: 'rgba(0, 0, 0, 0.85)',
              backdropFilter: 'blur(5px)',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              gap: '16px'
            }}>
              <div style={{ flex: 1, maxWidth: 'calc(100% - 76px)' }}>
                <h3 style={{
                  margin: 0,
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: '600',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {event.name}
                </h3>
                <p style={{
                  margin: '8px 0 0 0',
                  color: 'rgba(255, 255, 255, 0.8)',
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {event.location}
                </p>
              </div>
              
              {/* Circle Indicator */}
              <div style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: '#1651d4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
              }}>
                <span style={{
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}>
                  {formatDate(event.startTime)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Black Banner Section */}
  

      {/* Info Tiles Section */}
      <div style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: '20px',
        background: 'url(/Street.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center 85%',
        backgroundAttachment: 'fixed',
        minHeight: isMobile ? 'auto' : '100vh',
        padding: '20px',
        position: 'relative',
        marginBottom: isMobile ? '-100px' : '-150px'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '20px',
          width: '100%',
          maxWidth: '1400px',
          margin: isMobile ? '40px auto' : '160px auto',
          justifyContent: 'center'
        }}>
          {/* Pedestrians Tile */}
          <div style={{
            flex: 1,
            maxWidth: isMobile ? '100%' : '600px',
            padding: '30px',
            borderRadius: '16px',
            position: 'relative'
          }}>
            {/* Add a semi-transparent overlay */}
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(255, 255, 255, 0.9)',
              borderRadius: '16px'
            }} />
            
            {/* Header Section */}
            <div style={{
              background: '#1651d4',
              margin: '0px ',
              padding: ' 10px 30px ',
              borderRadius: '16px',
              border: '3px solid #1651d4',
              position: 'relative',
              zIndex: 1
            }}>
              {/* Inner white border */}
              <div style={{
                position: 'absolute',
                top: '3px',
                left: '3px',
                right: '3px',
                bottom: '3px',
                border: '4px solid white',
                borderRadius: '12px 12px',
                pointerEvents: 'none'
              }} />
              <h3 className="highwayfont" style={{
                fontSize: isMobile ? '2.5rem' : '4.5rem',
                margin: 0,
                color: 'white',
                position: 'relative'
              }}>
                For Pedestrians
              </h3>
            </div>

            <ul style={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
              fontSize: '24px',
              marginTop: '60px',
              lineHeight: '2',
              color: '#333',
              position: 'relative',
              zIndex: 1
            }}>
              {[
                { text: 'Find Events', icon: Search, color: '#FF4136' },
                { text: 'Declare Events', icon: MapPin, color: '#2ECC40' },
                { text: 'Follow your favorites', icon: Heart, color: '#B10DC9' },
                { text: 'Earn Mojo', icon: Trophy, color: '#FF851B' }
              ].map((item, index) => (
                <li key={index} style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: '20px',
                  marginBottom: '20px'
                }}>
                  <div style={{ 
                    width: '40px', 
                    height: '40px', 
                    background: item.color,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white'
                  }}>
                    <item.icon size={24} strokeWidth={3} />
                  </div>
                  <span style={{ fontWeight: 600 }}>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Performers Tile */}
          <div style={{
            flex: 1,
            maxWidth: isMobile ? '100%' : '600px',
            padding: '30px',
            borderRadius: '16px',
            position: 'relative'
          }}>
            {/* Add a semi-transparent overlay */}
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(55, 55, 55, 0.9)',
              borderRadius: '16px'
            }} />
            
            <h3 className="electronic" style={{
              fontSize: isMobile ? '2.2rem' : '3.6rem',
             marginTop: '0px',
              color: '#FFA500',
              background: 'black',
              padding: '18px 16px 10px 20px',
              width: 'calc(100% - 32px)',
              borderRadius: '8px',
              display: 'inline-block',
              position: 'relative',
              zIndex: 1
            }}>
              For Performers
            </h3>
            
            <ul style={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
              fontSize: '24px',
              lineHeight: '2',
              color: 'white',
              position: 'relative',
              zIndex: 1
            }}>
              {[
                { text: 'Share your links', icon: Link, color: '#0074D9' },
                { text: 'Connect with others', icon: Users, color: '#FF4136' },
                { text: 'Be noticed', icon: Star, color: '#FFDC00' }
              ].map((item, index) => (
                <li key={index} style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: '20px',
                  marginBottom: '20px'
                }}>
                  <div style={{ 
                    width: '40px', 
                    height: '40px', 
                    background: item.color,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white'
                  }}>
                    <item.icon size={24} />
                  </div>
                  <span style={{ fontWeight: 600 }}>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Mobile App Promotion */}
      <div style={{
        background: isMobile ? 
          'linear-gradient(180deg, rgba(22, 82, 212, 0) 0%, , #000000 20%)' : 
          'linear-gradient(180deg, rgba(22, 82, 212, 0) 0%,  #000000 20%)',
        padding: isMobile ? '120px 20px 40px' : '150px 20px 40px',
        color: 'white',
        textAlign: 'center',
        position: 'relative',
        zIndex: 1
      }}>
        <div style={{
          maxWidth: '1200px',
          margin: '0 auto',
          position: 'relative'
        }}>
          <h2 style={{
            fontSize: isMobile ? '1.5rem' : '2.5rem',
            marginBottom: '20px',
            fontWeight: '500'
          }}>
            Get the Full Experience
          </h2>
          <p style={{
            fontSize: isMobile ? '1rem' : '1.2rem',
            marginBottom: '30px',
            color: 'rgba(255,255,255,0.8)',
            maxWidth: '800px',
            margin: '0 auto 30px'
          }}>
            Download the mobile app to unlock all features:
          </p>
          <div style={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
            gap: isMobile ? '12px' : '20px',
            maxWidth: '900px',
            margin: '0 auto'
          }}>
            {[
              '📊 Check the Leaderboard',
              '🏆 Earn Mojo Points',
              '📍 Declare Events',
              '👥 Follow Performers',
              '🎮 Play Streetmoji Mobile',
              '🌟 Exclusive Features'
            ].map((feature, index) => (
              <div key={index} style={{
                background: 'rgba(255,255,255,0.1)',
                padding: isMobile ? '15px' : '20px',
                borderRadius: '12px',
                fontSize: isMobile ? '0.9rem' : '1.1rem'
              }}>
                {feature}
              </div>
            ))}
          </div>
          <img 
            src="/Download.svg" 
            alt="Download App"
            style={{
              marginTop: '40px',
              height: '50px',
              cursor: 'pointer'
            }}
            onClick={() => window.open('your-app-store-link', '_blank')}
          />
        </div>
      </div>

      <footer style={{
        padding: '15px',
        textAlign: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderTop: '1px solid #eee',
        boxShadow: '0 -2px 10px rgba(0,0,0,0.05)',
        zIndex: 1000,
        marginTop: '0px'
      }}>
        <a 
          href="/legal" 
          style={{
            color: '#333',
            textDecoration: 'none',
            fontSize: '0.9rem',
            padding: '5px 10px',
            borderRadius: '4px',
            transition: 'all 0.2s ease',
            ':hover': {
              backgroundColor: '#f5f5f5',
              color: '#1651D4'
            }
          }}
        >
          Terms of Service & Privacy Policy
        </a>
      </footer>
    </div>
  );
}

export default Home; 