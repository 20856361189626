import React, { useState } from 'react';

function BinaryConverter() {
  const [text, setText] = useState('');
  const [zeroEmoji, setZeroEmoji] = useState('❤️');
  const [oneEmoji, setOneEmoji] = useState('🦋');
  const [binaryResult, setBinaryResult] = useState('');
  const [binaryInput, setBinaryInput] = useState('');
  const [decodedText, setDecodedText] = useState('');
  const [binaryString, setBinaryString] = useState('');

  const textToBinary = (text) => {
    return text.split('').map(char => {
      const binary = char.charCodeAt(0).toString(2);
      // Pad with zeros to ensure 8 bits
      return '0'.repeat(8 - binary.length) + binary;
    }).join(' ');
  };

  const convertToBinaryEmojis = (text) => {
    if (!text) return '';
    const binary = textToBinary(text);
    return binary.split('').map(bit => {
      if (bit === '0') return zeroEmoji;
      if (bit === '1') return oneEmoji;
      return ' '; // Keep spaces between bytes
    }).join('');
  };

  const binaryToText = (binary) => {
    // Remove all spaces and split into 8-bit chunks
    const cleanBinary = binary.replace(/\s/g, '');
    const bytes = cleanBinary.match(/.{1,8}/g) || [];
    
    return bytes.map(byte => {
      const charCode = parseInt(byte, 2);
      return String.fromCharCode(charCode);
    }).join('');
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    setBinaryResult(convertToBinaryEmojis(newText));
  };

  const handleBinaryInput = (e) => {
    const input = e.target.value;
    setBinaryInput(input);

    try {
      // Convert emojis to binary directly
      let binaryString = '';
      for (const char of input) {
        // Normalize the comparison
        const normalizedChar = char.normalize();
        console.log('Processing char:', normalizedChar); // Debug log

        if (normalizedChar.includes('❤')) binaryString += '0';
        if (normalizedChar.includes('🦋')) binaryString += '1';
      }

      // Format binary string with spaces between bytes
      const formattedBinary = binaryString.match(/.{1,8}/g)?.join(' ') || binaryString;
      setBinaryString(formattedBinary);

      console.log('Binary string:', formattedBinary); // Debug log

      // Rest of the validation and conversion...
      if (binaryString.length === 0) {
        setDecodedText('not valid schmetterling binary');
        return;
      }

      if (binaryString.length % 8 !== 0) {
        console.log('Binary length:', binaryString.length);
        setDecodedText(`not valid schmetterling binary`);
        return;
      }

      const bytes = binaryString.match(/.{8}/g);
      console.log('Bytes:', bytes); // Debug log

      const decoded = bytes
        .map(byte => String.fromCharCode(parseInt(byte, 2)))
        .join('');
      
      console.log('Decoded:', decoded); // Debug log
      setDecodedText(decoded);

    } catch (error) {
      console.error('Decoding error:', error);
      setDecodedText('Invalid binary ');
    }
  };

  // Add a helper message component


  return (
    <div style={{
      maxWidth: '800px',
      margin: '40px auto',
      padding: '20px',
      fontFamily: 'monospace'
    }}>
      <h1 style={{
        marginBottom: '30px',
        color: '#333'
      }}>
        Binary Emoji Converter
      </h1>

      <div style={{
        display: 'flex',
        gap: '20px',
        marginBottom: '30px'
      }}>
        <div style={{ flex: 1 }}>
          <label style={{
            display: 'block',
            marginBottom: '8px',
            color: '#666'
          }}>
            Zero Emoji (0)
          </label>
          <input
            type="text"
            value={zeroEmoji}
            onChange={(e) => setZeroEmoji(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '20px',
              border: '2px solid #ddd',
              borderRadius: '8px'
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label style={{
            display: 'block',
            marginBottom: '8px',
            color: '#666'
          }}>
            One Emoji (1)
          </label>
          <input
            type="text"
            value={oneEmoji}
            onChange={(e) => setOneEmoji(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '20px',
              border: '2px solid #ddd',
              borderRadius: '8px'
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: '40px' }}>
        <h2 style={{ color: '#333', marginBottom: '20px' }}>Text to Binary</h2>
        <label style={{
          display: 'block',
          marginBottom: '8px',
          color: '#666'
        }}>
          Enter Text
        </label>
        <textarea
          value={text}
          onChange={handleTextChange}
          placeholder="Type something..."
          style={{
            width: '100%',
            padding: '15px',
            fontSize: '16px',
            border: '2px solid #ddd',
            borderRadius: '8px',
            minHeight: '100px',
            resize: 'vertical'
          }}
        />
        <div style={{
          marginTop: '20px',
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          fontSize: '20px',
          lineHeight: '1.5',
          wordBreak: 'break-all',
          minHeight: '100px'
        }}>
          {binaryResult || 'Schmetterling Binary output pulls up  here'}
        </div>
      </div>

      <div style={{ marginBottom: '30px' }}>
        <h2 style={{ color: '#333', marginBottom: '20px' }}>Raw Binary</h2>
        <div style={{
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          fontSize: '16px',
          fontFamily: 'monospace',
          lineHeight: '1.5',
          wordBreak: 'break-all',
          minHeight: '50px',
          color: '#666'
        }}>
          {binaryResult ? textToBinary(text) : 'Binary representation pulls up here...'}
        </div>
      </div>

      <div style={{ marginBottom: '30px' }}>
        <h2 style={{ color: '#333', marginBottom: '20px' }}>Binary to Text</h2>
        <label style={{
          display: 'block',
          marginBottom: '8px',
          color: '#666'
        }}>
          Enter Emojis
        </label>
        <textarea
          value={binaryInput}
          onChange={handleBinaryInput}
          placeholder={`Put emojis here (ex ${zeroEmoji}${oneEmoji}${zeroEmoji}${zeroEmoji}${oneEmoji})`}
          style={{
            width: '100%',
            padding: '15px',
            fontSize: '20px',
            border: '2px solid #ddd',
            borderRadius: '8px',
            minHeight: '100px',
            resize: 'vertical'
          }}
        />
        {/* Add binary representation */}
        <div style={{
          marginTop: '20px',
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          fontSize: '16px',
          fontFamily: 'monospace',
          lineHeight: '1.5',
          wordBreak: 'break-all',
          minHeight: '50px',
          color: '#666'
        }}>
          {binaryString || 'Binary representation  here...'}
        </div>

        <div style={{
          marginTop: '20px',
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          fontSize: '16px',
          lineHeight: '1.5',
          minHeight: '50px'
        }}>
          {decodedText || 'Decoded supa secret message here...'}
        </div>
      </div>
    </div>
  );
}

export default BinaryConverter; 