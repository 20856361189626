import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, collection, getDocs, Timestamp, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

function PerformerScan() {
  const { performerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkActiveEvents = async () => {
      try {
        const userDocRef = doc(db, 'Users', performerId);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          navigate(`/performer/${performerId}`);
          return;
        }
        
        const performancesRef = collection(db, `Users/${performerId}/performances`);
        const performancesSnapshot = await getDocs(performancesRef);
        const currentTime = Timestamp.now();
        
        const activePerformances = performancesSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(perf => 
            perf.startDate?.seconds <= currentTime.seconds && 
            perf.endDate?.seconds > currentTime.seconds
          )
          .sort((a, b) => b.startDate.seconds - a.startDate.seconds);

        if (activePerformances.length > 0) {
          navigate(`/performance/${activePerformances[0].id}`);
        } else {
          navigate(`/performer/${performerId}`);
        }
      } catch (error) {
        console.error('Error:', error);
        navigate(`/performer/${performerId}`);
      }
    };

    checkActiveEvents();
  }, [performerId, navigate]);

  return null; // No UI needed
}

export default PerformerScan; 