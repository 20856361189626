import React, { useState } from 'react';

function Schmetterling() {
  const [showHeart, setShowHeart] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  // Create butterfly positions for a heart shape
  const createHeartPositions = () => {
    const positions = [];
    for (let angle = 0; angle < 360; angle += 15) {
      const radian = (angle * Math.PI) / 180;
      // Heart shape formula - negative x and y to flip the heart
      const x = -(16 * Math.pow(Math.sin(radian), 3));
      const y = -(13 * Math.cos(radian) - 5 * Math.cos(2 * radian) - 2 * Math.cos(3 * radian) - Math.cos(4 * radian));
      positions.push({ x, y, delay: angle / 360 });
    }
    return positions;
  };

  const handleClick = () => {
    setIsAnimating(true);
    setShowHeart(true);
    setTimeout(() => setIsAnimating(false), 2000);
  };

  return (
    <div style={{
      height: '100vh',
      width: '100vw',
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'relative'
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        zIndex: 0,
        opacity: 0.2,
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px'
      }}>
        {Array(30).fill("February 20th is valentines day, not the 14th").map((text, index) => (
          <div
            key={index}
            style={{
              whiteSpace: 'nowrap',
              color: 'black',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              marginBottom: '10px',
              width: '100%',
              textAlign: 'center'
            }}
          >
            {text}
          </div>
        ))}
      </div>

      <div style={{ position: 'relative', zIndex: 1 }}>
        <style>
          {`
            @keyframes flyIn {
              0% {
                opacity: 0;
                transform: translate(-50px, -50px) rotate(-45deg);
              }
              50% {
                opacity: 1;
              }
              100% {
                opacity: 1;
                transform: translate(0, 0) rotate(0deg);
              }
            }
            
            @keyframes float {
              0%, 100% {
                transform: translateY(0) rotate(0deg);
              }
              50% {
                transform: translateY(-10px) rotate(5deg);
              }
            }
            
            .butterfly {
              position: absolute;
              font-size: 2rem;
              opacity: 0;
              animation: flyIn 1s forwards, float 3s ease-in-out infinite;
            }
          `}
        </style>

        {!showHeart && (
          <button
            onClick={handleClick}
            style={{
              padding: '15px 30px',
              fontSize: '1.5rem',
              background: 'rgba(0, 0, 0, 0.1)',
              border: '2px solid black',
              borderRadius: '25px',
              color: 'black',
              cursor: 'pointer',
              backdropFilter: 'blur(5px)',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'scale(1.05)'
              }
            }}
          >
            Piquale Aqui 🦋 ( no virus on skib)
          </button>
        )}

        {showHeart && (
          <div style={{
            position: 'relative',
            width: '300px',
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            {createHeartPositions().map((pos, index) => (
              <div
                key={index}
                className="butterfly"
                style={{
                  left: `${150 + pos.x * 8}px`,
                  top: `${150 + pos.y * 8}px`,
                  animationDelay: `${pos.delay}s`
                }}
              >
                🦋
              </div>
            ))}
          </div>
        )}

        {showHeart && !isAnimating && (
          <button
            onClick={() => {
              setShowHeart(false);
              setIsAnimating(false);
            }}
            style={{
              position: 'absolute',
              bottom: '40px',
              padding: '10px 20px',
              fontSize: '1rem',
              background: 'rgba(0, 0, 0, 0.1)',
              border: '2px solid black',
              borderRadius: '20px',
              color: 'black',
              cursor: 'pointer',
              backdropFilter: 'blur(5px)'
            }}
          >
            Reset
          </button>
        )}
      </div>
    </div>
  );
}

export default Schmetterling; 