import React, { useState, useRef } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { db, storage } from '../firebase';

function StreetmojiDeveloper() {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [emoji, setEmoji] = useState('');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === '0000') {
      setIsAuthenticated(true);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = (e) => {
    if (!imageRef.current) return;

    const rect = imageRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;
    
    setPosition({ x, y });
  };

  const handleUpload = async () => {
    if (!image || !emoji) return;

    const uploadDate = new Date(selectedDate);
    const docId = uploadDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
    
    try {
      const storageRef = ref(storage, `streetmoji/${docId}.jpg`);
      await uploadBytes(storageRef, image);
      const imageURL = await getDownloadURL(storageRef);

      const docData = {
        imageURL,
        emoji,
        emojiX: position.x,
        emojiY: position.y,
        date: Timestamp.fromDate(uploadDate),
        createdAt: Timestamp.now()
      };

      await setDoc(doc(db, 'streetmojiweb', docId), docData);
      alert('Streetmoji uploaded successfully!');
      
      // Clear form
      setImage(null);
      setImagePreview(null);
      setEmoji('');
      setPosition({ x: 0, y: 0 });
    } catch (error) {
      console.error('Error uploading:', error);
      alert('Error uploading: ' + error.message);
    }
  };

  if (!isAuthenticated) {
    return (
      <div style={{ padding: '20px' }}>
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Upload Streetmoji</h1>
      
      <div style={{ marginBottom: '20px' }}>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          style={{ 
            padding: '5px',
            marginBottom: '10px',
            width: '200px'
          }}
        />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ marginBottom: '10px' }}
        />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          value={emoji}
          onChange={(e) => setEmoji(e.target.value)}
          placeholder="Enter emoji"
          style={{ padding: '5px' }}
        />
      </div>

      {imagePreview && (
        <div style={{ position: 'relative', marginBottom: '20px' }}>
          <img
            ref={imageRef}
            src={imagePreview}
            alt="Preview"
            style={{ width: '100%', cursor: 'crosshair' }}
            onClick={handleImageClick}
          />
          {/* Circle indicator */}
          {position.x !== 0 && position.y !== 0 && (
            <div
              style={{
                position: 'absolute',
                left: `${position.x * 100}%`,
                top: `${position.y * 100}%`,
                transform: 'translate(-50%, -50%)',
                width: '50px',
                height: '50px',
                border: '2px solid red',
                borderRadius: '50%',
                pointerEvents: 'none'
              }}
            />
          )}
          {/* Emoji overlay */}
          {emoji && (
            <div
              style={{
                position: 'absolute',
                left: `${position.x * 100}%`,
                top: `${position.y * 100}%`,
                transform: 'translate(-50%, -50%)',
                fontSize: '24px',
                pointerEvents: 'none'
              }}
            >
              {emoji}
            </div>
          )}
        </div>
      )}

      <div>
        <button
          onClick={handleUpload}
          style={{
            padding: '10px 20px',
            background: '#1651D4',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Upload
        </button>
      </div>

      {position.x !== 0 && position.y !== 0 && (
        <div style={{ marginTop: '20px' }}>
          <p>Selected Position: {position.x.toFixed(4)}, {position.y.toFixed(4)}</p>
        </div>
      )}
    </div>
  );
}

export default StreetmojiDeveloper; 