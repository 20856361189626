import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

function StreetmojiResults() {
  const navigate = useNavigate();
  const { date, time, success, streak, emoji } = useParams();
  const [imageUrl, setImageUrl] = useState(null);
  const [imageWidth, setImageWidth] = useState(1200);
  const [imageHeight, setImageHeight] = useState(630);

  useEffect(() => {
    const fetchStreetmojiImage = async () => {
      // Convert the ISO date to the format used in Firestore (MMDDYYYY)
      const dateObj = new Date(date);
      const docId = dateObj.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).replace(/\//g, '');
      
      try {
        const docRef = doc(db, 'streetmojiweb', docId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const url = docSnap.data().imageURL;
          setImageUrl(url);
          
          // Get image dimensions
          const img = new Image();
          img.onload = () => {
            setImageWidth(img.width);
            setImageHeight(img.height);
          };
          img.src = url;
        }
      } catch (error) {
        console.error('Error fetching Streetmoji image:', error);
      }
    };

    fetchStreetmojiImage();
  }, [date]);

  useEffect(() => {
    // Redirect to home after a brief delay
    const timer = setTimeout(() => {
      navigate('/streetmoji');
    }, 1000); // Increased delay for meta tags
    return () => clearTimeout(timer);
  }, [navigate]);

  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  const streakText = parseInt(streak) > 0 ? ` (${streak}🔥)` : '';
  const shareText = success === 'true'
    ? `I found the ${emoji} in ${time} on Streetmoji${streakText} (${formattedDate})`
    : `I did not find the ${emoji} after ${time} of searching on Streetmoji${streakText}, Can you? (${formattedDate})`;

  // Fallback image if the day's image isn't loaded yet
  const fallbackImage = 'https://ondast.web.app/OndaStreetMojiFinished.svg';
  const baseUrl = 'https://ondast.web.app';
  const faviconUrl = `${baseUrl}/favicon.ico`;

  return (
    <>
      <Helmet>
        <title>{shareText} - Streetmoji by Onda St.</title>
        <link rel="icon" type="image/x-icon" href={faviconUrl} />
        <meta name="description" content={shareText} />
        
        {/* OpenGraph Meta Tags */}
        <meta property="og:site_name" content="Onda St." />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={shareText} />
        <meta property="og:description" content="Play Streetmoji - Daily Emoji Hunt by Onda St." />
        {imageUrl && (
          <>
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:secure_url" content={imageUrl} />
            <meta property="og:image:width" content={imageWidth.toString()} />
            <meta property="og:image:height" content={imageHeight.toString()} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:alt" content={`Streetmoji challenge for ${formattedDate}`} />
          </>
        )}
        <meta property="og:url" content={window.location.href} />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@streetmoji" />
        <meta name="twitter:title" content={shareText} />
        <meta name="twitter:description" content="Play Streetmoji - Daily Emoji Hunt by Onda St." />
        {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      </Helmet>
      <div style={{ display: 'none' }}>
        <h1>{shareText}</h1>
        {imageUrl && (
          <img 
            src={imageUrl} 
            alt={`Streetmoji challenge for ${formattedDate}`}
            width={imageWidth}
            height={imageHeight}
          />
        )}
        <p>Play Streetmoji - Daily Emoji Hunt by Onda St.</p>
      </div>
    </>
  );
}

export default StreetmojiResults; 