import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, Timestamp, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { Check, ChevronLeft, Share, X } from 'lucide-react';
import MetaTags from '../components/MetaTags.jsx';
import { Helmet } from 'react-helmet';
import { rateLimit, rateLimitWrite } from '../utils/rateLimiter';

// Move helper functions outside the component
const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

const calculateDistance = (guess, target) => {
  const dx = (guess.x - target.x);
  const dy = (guess.y - target.y);
  const distance = Math.sqrt(dx * dx + dy * dy);
  
  const isWithinTolerance = distance <= 0.15; // Match the tolerance in isGuessCorrect
  
  // Add more detailed logging
  console.log('Distance calculation details:', {
    guess: { x: guess.x, y: guess.y },
    target: { x: target.x, y: target.y },
    dx,
    dy,
    rawDistance: distance,
    percentageDistance: distance * 100,
    isWithinTolerance,
    toleranceUsed: 0.15
  });
  
  return { distance, isWithinTolerance };
};

const calculateMojo = (seconds, streak) => {
  // Base mojo calculation (exponential decay from 100 to 10 over 6 minutes)
  const baseTime = 360; // 6 minutes in seconds
  const minMojo = 10;
  const maxMojo = 100;
  
  let timeMojo;
  if (seconds >= baseTime) {
    timeMojo = minMojo;
  } else {
    // Exponential decay formula
    const decay = Math.exp(-5 * seconds / baseTime);
    timeMojo = minMojo + (maxMojo - minMojo) * decay;
  }
  
  // Add bonus mojo
  const completionBonus = 5;
  const streakBonus = streak;
  
  return Math.round(timeMojo + completionBonus + streakBonus);
};

// First, update the isGuessCorrect function to use a more lenient tolerance
const isGuessCorrect = (distance) => {
  const toleranceRaw = 0.15; // 15% tolerance
  const isCorrect = distance <= toleranceRaw;
  console.log('Guess validation:', { 
    rawDistance: distance, 
    tolerance: toleranceRaw, 
    isCorrect,
    distancePercentage: distance * 100
  });
  return isCorrect;
};

// Update CompletionScreen component definition to accept streetmoji prop
const CompletionScreen = ({ score, streak, formatTime, navigate, streetmoji }) => {
  const handleShare = async () => {
    const today = new Date().toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });

    const streakText = streak > 0 ? ` (${streak}🔥)` : '';
    const shareText = score.success 
      ? `I found the ${streetmoji.emoji} in ${formatTime(score.time)} on Streetmoji${streakText} (${today})`
      : `I did not find the ${streetmoji.emoji} after ${formatTime(score.time)} of searching on Streetmoji${streakText} (${today})`;

    const shareUrl = 'https://ondast.web.app/streetmoji';

    if (navigator.share) {
      try {
        await navigator.share({
          title: `Streetmoji`,
          text: shareText,
          url: shareUrl
        });
      } catch (error) {
        console.log('Error sharing:', error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(`${shareText}\n${shareUrl}`);
        alert('Copied to clipboard!');
      } catch (error) {
        console.log('Error copying to clipboard:', error);
      }
    }
  };

  const isMobile = window.innerWidth <= 480;

  return (
    
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(45deg, #1651D4,rgb(255, 255, 255))',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '24px',
      zIndex: 1000,
    }}>
         <button
          onClick={() => navigate('/')}
          style={{
            backgroundColor: 'lightgrey',
            color: 'black',
            opacity: '50%',
            padding: '5px',
            height: '40px',
            width: '40px',
            borderRadius: '20px',
            position: 'absolute',
            top: '20px',
            left: '20px',
            border: 'none',
            fontSize: '18px',
            fontWeight: '600',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ChevronLeft style={{ marginTop: '3px' }} />
        </button>

      <div style={{
        background: 'rgba(0, 0, 0, 0.7)',
        padding: isMobile ? '40px 20px' : '60px',
        position: 'relative',
        borderRadius: '20px',
        width: isMobile ? '85%' : '500px',
        boxSizing: 'border-box'
      }}>
        <img
          src="/OndaStreetMojiFinished.svg"
          alt="Onda"
          style={{
            width: isMobile ? '100%' : '50%',
       
            display: 'block',
            margin: '0 auto'
          }}
        />

        <div className="electronic" style={{
          fontSize: isMobile ? '1.4rem' : '2.5rem',
          color: '#FF9500',
          marginTop: '1.5rem',
          textAlign: 'center'
        }}>
          {new Date().toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </div>

        <div className="electronic" style={{
          fontSize: isMobile ? '1.4rem' : '2.5rem',
          color: '#FF9500',
          marginTop: '0.75rem',
          textAlign: 'center'
        }}>
          Finished in - {formatTime(score.time)}
        </div>

        <div className="electronic" style={{
          fontSize: isMobile ? '1.4rem' : '2.5rem',
          color: '#FF9500',
          marginTop: '0.75rem',
          textAlign: 'center'
        }}>
          {streak} day streak
        </div>

        <div className="electronic" style={{
          fontSize: isMobile ? '1.4rem' : '2.5rem',
          color: score.success ? '#FF9500' : '#FF0000',
          marginTop: '0.75rem',
          textAlign: 'center'
        }}>
          {score.success ? (
            <>
              mojo +{score.mojo}
              <div style={{ fontSize: '0.8em', opacity: 0.8 }}>
                Total Mojo: {score.totalMojo}
              </div>
            </>
          ) : (
            <>
              Failed - Try Again Tomorrow
              <div style={{ fontSize: '0.8em', opacity: 0.8 }}>
                Current Mojo: {score.totalMojo}
              </div>
            </>
          )}
        </div>

        {/* Navigation Buttons */}
     
        <button
          onClick={handleShare}
          style={{
            backgroundColor: 'transparent',
            color: '#FF9500',
            padding: '5px',
            height: '40px',
            width: '40px',
            borderRadius: '20px',
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            border: 'none',
            fontSize: '18px',
            fontWeight: '600',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Share style={{ marginTop: '3px' }} />
        </button>
      </div>

      <div style={{
        position: 'fixed',
        bottom: '50px',
        width: '100%',
        textAlign: 'center',
        zIndex: 11
      }}>
        <a 
          href="https://docs.google.com/forms/d/e/1FAIpQLSfV7YVVZU1ep7Y-QCicoLAVhePmNdeSkY-lptZ5l_SyGCYWxw/viewform?usp=header"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#6FA1FF',
            textDecoration: 'underline',
            fontSize: '14px',
            padding: '10px',
            cursor: 'pointer'
          }}
        >
          submit your photos to be used in streetmoji
        </a>
      </div>
    </div>
  );
};

// Add this helper function at the top
const isMacOrIOS = () => {
  const platform = navigator.platform.toLowerCase();
  const userAgent = navigator.userAgent.toLowerCase();
  return platform.includes('mac') || 
         userAgent.includes('iphone') || 
         userAgent.includes('ipad') || 
         userAgent.includes('ipod');
};

// Add this at the top of your component
const MobileView = ({ 
  streetmoji, 
  elapsedTime, 
  streak, 
  guess, 
  hasCompletedToday, 
  handleSubmitGuess,
  handleDoubleClick,
  handleMouseDown,
  handleWheel,
  scale,
  viewPosition,
  isDragging,
  imageRef,
  containerRef,
  setIsComplete,
  setScore,
  formatTime,
  isComplete,
  score,
  navigate,
  setHasCompletedToday,
  showFailureAnimation,
  showCorrectLocation,
  setShowFailureAnimation,
  setShowCorrectLocation
}) => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isComplete ? (
        <CompletionScreen 
          score={score} 
          streak={streak}
          formatTime={formatTime}
          navigate={navigate}
          streetmoji={streetmoji}
        />
      ) : (
        <div style={{
          height: '100vh', // Full viewport height
          display: 'flex',
          flexDirection: 'column',
          background: 'linear-gradient(60deg,rgb(13, 0, 201),rgb(111, 157, 255))',
          position: 'relative'
        }}>
          {/* Header */}
          <div style={{
            padding: '10px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'rgba(0, 0, 0, 0.9)',
            height: '40px'
          }}>
            <span className="electronic" style={{ 
              color: '#FF9500',
              fontSize: '0.8rem' 
            }}>
              {new Date().toLocaleDateString('en-US', { 
                month: 'long', 
                year: 'numeric', 
                day: 'numeric'
              })} -  {streak}🔥
            </span>

            <div className="electronic" style={{
              fontSize: '.8rem',
              color: '#FF9500'
            }}>
              Time Elapsed  {formatTime(elapsedTime)}
            </div>
          </div>

          {/* Title/Label above image */}
          <div style={{
            position: 'fixed',
            top: '100px', // Below header
            width: '100%',
            textAlign: 'center',
            color: 'white',
            fontSize: '2rem',
            fontWeight: '600',
            zIndex: 8,
            padding: '20px 0'
          }}>
            StreetMoji {streetmoji.emoji}
          </div>

          {/* Main content area with image */}
          <div style={{
            position: 'fixed',
            top: '180px', // Positioned below the title
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 7
          }}>
            {/* Image container - keep original square dimensions */}
            <div 
              ref={containerRef}
              style={{
                width: '90%',
                aspectRatio: '1/1',
                overflow: 'hidden',
                cursor: scale > 1 ? (isDragging ? 'grabbing' : 'grab') : 'default',
                userSelect: 'none',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid rgba(255,255,255,0.2)',
                borderRadius: '24px',
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(5px)'
              }}
              onWheel={handleWheel}
              onMouseDown={handleMouseDown}
              onDoubleClick={handleDoubleClick}
            >
              <div style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                transformOrigin: 'center',
                transform: showCorrectLocation 
                  ? `translate(calc(-${streetmoji.emojiX * 100}% + 50%), calc(-${streetmoji.emojiY * 100}% + 50%)) scale(2)`
                  : `scale(${scale}) translate(${viewPosition.x}px, ${viewPosition.y}px)`,
                transition: showCorrectLocation ? 'transform 1s ease-out' : 
                  (isDragging ? 'none' : 'transform 0.1s ease-out')
              }}>
                <img 
                  ref={imageRef}
                  src={streetmoji.imageURL}
                  alt="Find the Streetmoji"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
                
                {/* Guess indicator */}
                {guess && (
                  <div style={{
                    position: 'absolute',
                    left: `${guess.x * 100}%`,
                    top: `${guess.y * 100}%`,
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    border: '2px solid #1651d4',
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    zIndex: 10
                  }}/>
                )}

                {/* Correct location indicator */}
                {showCorrectLocation && (
                  <div style={{
                    position: 'absolute',
                    left: `${streetmoji.emojiX * 100}%`,
                    top: `${streetmoji.emojiY * 100}%`,
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    backgroundColor: 'transparent',
                    border: '3px solid #4CAF50',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: '0 0 20px rgba(0,0,0,0.3)',
                    zIndex: 1000,
                    animation: 'fadeIn 0.5s ease-out',
                    pointerEvents: 'none'
                  }}/>
                )}
              </div>
            </div>
          </div>

          {/* Bottom hint text */}
          <div style={{
            position: 'fixed',
            bottom: '85px', // Just above guess button
            width: '100%',
            textAlign: 'center',
            color: '#6FA1FF',
            fontSize: '14px',
            zIndex: 9
          }}>
            double tap to place selector
          </div>

          {/* Fixed bottom guess button */}
          <div style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '15px',
            background: 'rgba(0, 0, 0)',
            zIndex: 10
          }}>
            <div style={{position: 'absolute', height: '2px', width: '100%', background: 'white', marginLeft: '-15px', top: '10px'}}/>
            <div onClick={handleSubmitGuess}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                opacity: guess && !hasCompletedToday ? 1 : 0.5,
                cursor: guess && !hasCompletedToday ? 'pointer' : 'not-allowed'
              }}
              disabled={!guess || hasCompletedToday}>
              <h1 style={{fontSize: '2rem', height: '0px', lineHeight: '0px', color: 'white', fontWeight: '600'}}>
                Guess
              </h1>
              <div style={{
                padding: '10px',
                backgroundColor: 'white',
                borderRadius: '100px',
                marginLeft: 'auto',
                marginTop: '5px',
                lineHeight: '25px',
                fontSize: '20px'
              }}>
                {streetmoji.emoji}
              </div>
              <Check 
                style={{
                  color: 'white',
                  padding: '10px',
                  backgroundColor: 'green',
                  borderRadius: '100px',
                  marginLeft: '10px',
                  marginTop: '5px'
                }}
                strokeWidth={4}
              />
            </div>
          </div>

          {/* Failure Animation */}
          {showFailureAnimation && (
            <div style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.5)'
            }}>
              <X 
                className="failure-cross"
                size={120}
                color="red"
                strokeWidth={4}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

function StreetmojiPlay() {
  const navigate = useNavigate();
  const [streetmoji, setStreetmoji] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(() => {
    const cached = localStorage.getItem('streetmoji_elapsed_time');
    return cached ? parseInt(cached) : 0;
  });
  const [startTimestamp, setStartTimestamp] = useState(() => {
    const cached = localStorage.getItem('streetmoji_start_timestamp');
    return cached ? parseInt(cached) : Date.now();
  });
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const startTimeRef = useRef(Date.now());
  const [guess, setGuess] = useState(null);
  const [viewPosition, setViewPosition] = useState({ x: 0, y: 0 });
  const lastMousePos = useRef({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const imageRef = useRef(null);  
  
  const [showFailureAnimation, setShowFailureAnimation] = useState(false);
  const [showCorrectLocation, setShowCorrectLocation] = useState(false);

  const [isComplete, setIsComplete] = useState(false);
  const [score, setScore] = useState(null);
  const [streak, setStreak] = useState(() => {
    const streakData = JSON.parse(localStorage.getItem('streetmoji_streak') || '{"count":0,"lastPlayed":null}');
    
    const today = new Date().toDateString();
    const lastPlayed = streakData.lastPlayed ? new Date(streakData.lastPlayed).toDateString() : null;
    const yesterday = new Date(Date.now() - 86400000).toDateString();
    
    if (!lastPlayed || (lastPlayed !== today && lastPlayed !== yesterday)) {
      return 0;
    }
    
    return streakData.count;
  });
  const [hasCompletedToday, setHasCompletedToday] = useState(false);
  const [showPenalty, setShowPenalty] = useState(false);
  const [penaltyTimer, setPenaltyTimer] = useState(null);
  const [cachedResult, setCachedResult] = useState(null);

  // Add platform check
  const [isCompatiblePlatform] = useState(isMacOrIOS());

  // Add isMobile state
  const [isMobile] = useState(window.innerWidth <= 480);

  // Add activeSession tracking
  const [isActiveSession, setIsActiveSession] = useState(true);

  // Add new state variables at the top
  const [imageLoaded, setImageLoaded] = useState(false);
  const [totalMojo, setTotalMojo] = useState(0);

  // Replace user ID fetch with localStorage
  useEffect(() => {
    // Get total mojo from localStorage
    const savedMojo = localStorage.getItem('streetmoji_total_mojo');
    setTotalMojo(savedMojo ? parseInt(savedMojo, 10) : 0);
  }, []);

  // Update the timer start logic
  useEffect(() => {
    if (!imageLoaded || isComplete) return;

    const timer = setInterval(() => {
      setElapsedTime(prev => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [imageLoaded, isComplete]);

  // Move all useEffect hooks here, right after state declarations
  useEffect(() => {
    const fetchTodaysStreetmoji = async () => {
      try {
        const today = new Date();
        const docId = today.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).replace(/\//g, '');
        
        const docRef = doc(db, 'streetmojiweb', docId);
        
        // Wrap the Firebase read operation with rate limiting
        const docSnap = await rateLimit(
          () => getDoc(docRef),
          true // isStreetmoji = true for stricter limits
        );
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          setStreetmoji(data);
        } else {
          console.log('No Streetmoji document found for today');
        }
      } catch (error) {
        if (error.message.includes('Rate limit')) {
          // Handle rate limit error
          console.log('Rate limit reached:', error.message);
          // Maybe show a user-friendly message
        } else {
          console.error('Error fetching Streetmoji:', error);
        }
      }
    };

    fetchTodaysStreetmoji();
  }, []);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging, lastMousePos]);

  useEffect(() => {
    return () => {
      if (penaltyTimer) clearTimeout(penaltyTimer);
    };
  }, [penaltyTimer]);

  useEffect(() => {
    // Check for cached result when component mounts
    const today = new Date().toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
    
    const cached = localStorage.getItem(`streetmoji_result_${today}`);
    if (cached) {
      const parsedCache = JSON.parse(cached);
      setCachedResult(parsedCache);
      setScore(parsedCache);
      setIsComplete(true);
      setHasCompletedToday(true);
    }
  }, []);

  useEffect(() => {
    // Only start counting if it's an active session and not complete
    if (isComplete) return;

    const timer = setInterval(() => {
      if (isActiveSession) {
        setElapsedTime(prev => {
          const newTime = prev + 1;
          localStorage.setItem('streetmoji_elapsed_time', newTime.toString());
          return newTime;
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [isComplete, isActiveSession]);

  // Add visibility change handler
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // User left the page
        setIsActiveSession(false);
        localStorage.setItem('streetmoji_elapsed_time', elapsedTime.toString());
      } else {
        // User returned to the page
        setIsActiveSession(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [elapsedTime]);

  // Reset timer when component mounts for a new day
  useEffect(() => {
    const today = new Date().toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');

    const lastPlayedDay = localStorage.getItem('streetmoji_last_played_day');
    
    if (lastPlayedDay !== today) {
      // Reset for new day
      localStorage.setItem('streetmoji_elapsed_time', '0');
      localStorage.setItem('streetmoji_start_timestamp', Date.now().toString());
      localStorage.setItem('streetmoji_last_played_day', today);
      setElapsedTime(0);
      setStartTimestamp(Date.now());
    }
  }, []);

  const handleWheel = (e) => {
    e.preventDefault();
    const delta = e.deltaY * -0.01;
    const newScale = Math.min(Math.max(1, scale + delta), 4);
    
    if (newScale === 1) {
      setViewPosition({ x: 0, y: 0 });
    } else {
      const rect = containerRef.current.getBoundingClientRect();
      const mouseX = (e.clientX - rect.left) / rect.width * 100;
      const mouseY = (e.clientY - rect.top) / rect.height * 100;

      setViewPosition(prev => {
        if (newScale === 1) return { x: 0, y: 0 };
        
        const dx = (mouseX - 50) * (newScale - scale) / newScale;
        const dy = (mouseY - 50) * (newScale - scale) / newScale;
        
        return {
          x: prev.x - dx,
          y: prev.y - dy
        };
      });
    }
    
    setScale(newScale);
  };

  const handleMouseDown = (e) => {
    if (scale === 1) return; // Don't allow dragging at default zoom
    e.preventDefault();
    setIsDragging(true);
    lastMousePos.current = { x: e.clientX, y: e.clientY };
  };

  const handleMouseMove = (e) => {
    if (isDragging && scale > 1) {
      const dx = e.clientX - lastMousePos.current.x;
      const dy = e.clientY - lastMousePos.current.y;
      
      // Calculate bounds based on container and scale
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      
      // Maximum offset increases with zoom level
      const maxOffset = containerWidth * (scale - 1) / 2;
      
      setViewPosition(prev => ({
        x: Math.min(maxOffset, Math.max(-maxOffset, prev.x + dx)),
        y: Math.min(maxOffset, Math.max(-maxOffset, prev.y + dy))
      }));
      
      lastMousePos.current = { x: e.clientX, y: e.clientY };
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleDoubleClick = (e) => {
    if (!imageRef.current) return;
    
    const rect = imageRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;
    
    // Only set guess if click is within image bounds
    if (x >= 0 && x <= 1 && y >= 0 && y <= 1) {
      setGuess({ x, y });
      console.log('Guess coordinates:', { x, y }); // For debugging
    }
  };

  const handleSubmitGuess = async () => {
    if (!guess || hasCompletedToday) return;

    const { distance, isWithinTolerance } = calculateDistance(guess, {
      x: streetmoji.emojiX,
      y: streetmoji.emojiY
    });

    if (!isWithinTolerance) {
      setShowFailureAnimation(true);
      setTimeout(() => {
        setShowFailureAnimation(false);
        setShowCorrectLocation(true);
        setTimeout(() => {
          setIsComplete(true);
          setScore({
            success: false,
            time: elapsedTime,
            distance: distance * 100,
            totalMojo: totalMojo // Include current total mojo in score
          });
        }, 3000);
      }, 2000);
      return;
    }

    try {
      const newMojo = calculateMojo(elapsedTime, streak);
      const updatedTotalMojo = totalMojo + newMojo;

      // Update total mojo in localStorage
      localStorage.setItem('streetmoji_total_mojo', updatedTotalMojo.toString());

      setTotalMojo(updatedTotalMojo);
      setIsComplete(true);
      setScore({
        success: true,
        time: elapsedTime,
        mojo: newMojo,
        totalMojo: updatedTotalMojo
      });
    } catch (error) {
      console.error('Error updating mojo:', error);
    }
  };

  if (!streetmoji) {
    if (cachedResult) {
      return <CompletionScreen 
        score={cachedResult} 
        streak={streak}
        formatTime={formatTime}
        navigate={navigate}
        streetmoji={streetmoji}
      />;
    }
    return <div>Loading today's Streetmoji...</div>;
  }

  return (
    <>
      <MetaTags streetmoji={streetmoji} />
      <style>
  {`
    .header-container {
      width: calc(100% - 500px);
      background: black;
      opacity: .9;
      padding: 4px 20px;
      height: 45px;
      position: absolute;
      top: 0px;
      left: 400px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .header-text {
      font-size: 1.8rem;
      color: orange;
    }
  `}
</style>
      
      {!streetmoji ? (
        <div>Loading...</div>
      ) : !isCompatiblePlatform ? (
        // Platform restriction message
        <div style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          background: 'linear-gradient(45deg, #1651D4, rgb(255, 255, 255))',
          color: 'white',
          textAlign: 'center'
        }}>
          <div style={{
            background: 'rgba(0, 0, 0, 0.7)',
            padding: '40px',
            borderRadius: '20px',
            maxWidth: '600px'
          }}>
            <img
              src="/OndaStreetMojiFinished.svg"
              alt="Onda"
              style={{
                height: '50px',
                marginBottom: '20px'
              }}
            />
            <h2 className="electronic" style={{
              fontSize: '2.5rem',
              color: '#FF9500',
              marginBottom: '20px'
            }}>
              Mac & iOS Exclusive
            </h2>
            <p style={{
              fontSize: '1.2rem',
              marginBottom: '30px',
              color: '#FF9500'
            }}>
              StreetMoji is currently available exclusively for Mac and iOS users.
              <br/><br/>
              Download our iOS app to play!
            </p>
            <button
              onClick={() => navigate('/')}
              style={{
                backgroundColor: 'lightgrey',
                color: 'black',
                opacity: '50%',
                padding: '5px',
                height: '40px',
                width: '40px',
                borderRadius: '20px',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto'
              }}
            >
              <ChevronLeft />
            </button>
          </div>
        </div>
      ) : isMobile ? (
        <MobileView 
          streetmoji={streetmoji}
          elapsedTime={elapsedTime}
          streak={streak}
          guess={guess}
          hasCompletedToday={hasCompletedToday}
          handleSubmitGuess={handleSubmitGuess}
          handleDoubleClick={handleDoubleClick}
          handleMouseDown={handleMouseDown}
          handleWheel={handleWheel}
          scale={scale}
          viewPosition={viewPosition}
          isDragging={isDragging}
          imageRef={imageRef}
          containerRef={containerRef}
          setIsComplete={setIsComplete}
          setScore={setScore}
          formatTime={formatTime}
          isComplete={isComplete || !!cachedResult}
          score={score || cachedResult}
          navigate={navigate}
          setHasCompletedToday={setHasCompletedToday}
          showFailureAnimation={showFailureAnimation}
          showCorrectLocation={showCorrectLocation}
          setShowFailureAnimation={setShowFailureAnimation}
          setShowCorrectLocation={setShowCorrectLocation}
        />
      ) : (
        <>
        {(isComplete || cachedResult) ? (
          <CompletionScreen 
            score={score || cachedResult} 
            streak={streak}
            formatTime={formatTime}
            navigate={navigate}
            streetmoji={streetmoji}
          />
        ) : (
          <div style={{ 
            height: '100vh',
            width: '100vw',
            background: 'linear-gradient(60deg,rgb(13, 0, 201),rgb(111, 157, 255))',
            display: 'flex',
            gap: '24px',
            overflow: 'hidden',
            boxSizing: 'border-box'
          }}>
            {/* Left Column - with glass effect */}
            <div style={{
              flex: '0 0 400px',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
              boxSizing: 'border-box',
              background: 'rgba(255, 255, 255, 0.99)',
              backdropFilter: 'blur(10px)',
              padding: '20px',
              position: 'relative',
              boxShadow: '4px 0 20px rgba(0, 0, 0, 0.1)'
            }}>
              {/* Content Container */}
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                height: '100%',
                boxSizing: 'border-box'
              }}>
                {/* Logo now aligned with content */}
               

             
                <div style={{
                fontSize: '2.5rem',
                fontWeight: 'bold',
                display: 'flex',
                lineHeight: 1.1,
                width: '300px',
                margin: '20px 0'
              }}> 
                  
                  <img 
                    src="/Onda.svg" 
                    alt="Play Streetmoji"
                    onClick={() => navigate('/streetmoji')}
                    style={{
                      height: '30px',
                      marginTop: '-30px',
                      width: 'auto',
                      transition: 'transform 0.2s',
                      ':hover': {
                        transform: 'scale(1.05)'
                      }
                    }}
                  />
                
              </div>
            
                <h1 style={{
                  fontSize: '4rem',
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                  margin: 0,
                  marginLeft: '-4px',
                  marginTop: '-50px'
                }}>
              <br/> <br/>
              StreetMoji:<br/>
                  Find the<br/> 
                  emoji ({streetmoji.emoji})
                </h1>

                {/* Stats - Moved from right column */}
            





            
                {/* Instructions and Guess Button */}
                <div style={{
                  marginTop: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}>
                  <div style={{
                    fontSize: '14px',
                    color: 'lightgrey',
                    textAlign: 'center',
                    marginBottom: '30px'
                  }}>
                    double tap to place selector
                  </div>

                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    opacity: guess && !hasCompletedToday ? 1 : 0.5,
                    cursor: guess && !hasCompletedToday ? 'pointer' : 'not-allowed',
                    background: 'rgba(0, 0, 0, 0.9)',
                    padding: '15px',
                    margin: '-20px', position: 'relative',
                  }}
                  onClick={handleSubmitGuess}
                  disabled={!guess || hasCompletedToday}>
                    <h1 style={{
                      fontSize: '2rem',
                      height: '0px',
                      lineHeight: '0px',
                      color: 'white',
                      fontWeight: '600',
                      margin: 0
                    }}>
                      Guess
                    </h1>
                    
                  <div style={{height: "4px", width: '100%', background: 'white', position: 'absolute', top:' 10px'}}/>

                    <Check 
                      style={{
                        color: 'white',
                        padding: '10px',
                        backgroundColor: 'green',
                        borderRadius: '100px',
                        marginLeft: 'auto',
                        marginTop: '5px'
                      }}
                      strokeWidth={4}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Center Column - Reorganized */}
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              boxSizing: 'border-box',
              gap: '20px'
            }}>

<div className="header-container">
  <span className="header-text">StreetMoji</span>
  <span className="header-text">
    {new Date().toLocaleDateString('en-US', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric' 
    })}
  </span>
  <span className="header-text">{streak} Day Streak</span>
  <span className="header-text">Time: {formatTime(elapsedTime)}</span>
</div>

              {/* Logo moved above image */}
            

              {/* Image container with reduced height */}
              <div 
                ref={containerRef}
                style={{
                  height: '90%',
                  maxWidth: '90%',
                  position: 'relative',
                  marginTop: '40px',
                  overflow: 'hidden',
                  cursor: scale > 1 ? (isDragging ? 'grabbing' : 'grab') : 'default',
                  userSelect: 'none',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: '24px',
                  boxShadow: '0 4px 24px rgba(0,0,0,0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(5px)'
                }}
                onWheel={handleWheel}
                onMouseDown={handleMouseDown}
                onDoubleClick={handleDoubleClick}
              >
                <div style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  transformOrigin: 'center',
                  transform: showCorrectLocation 
                    ? `translate(calc(-${streetmoji.emojiX * 100}% + 50%), calc(-${streetmoji.emojiY * 100}% + 50%)) scale(2)`
                    : `scale(${scale}) translate(${viewPosition.x}px, ${viewPosition.y}px)`,
                  transition: showCorrectLocation ? 'transform 1s ease-out' : 
                    (isDragging ? 'none' : 'transform 0.1s ease-out'),
                }}>
                  <img 
                    ref={imageRef}
                    src={streetmoji.imageURL}
                    alt="Find the Streetmoji"
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover'
                    }}
                    onLoad={() => setImageLoaded(true)}
                  />
                  
                  {/* User's guess dot */}
                  {guess && (
                    <div style={{
                      position: 'absolute',
                      left: `${guess.x * 100}%`,
                      top: `${guess.y * 100}%`,
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '2px solid #1651d4',
                      transform: 'translate(-50%, -50%)',
                      pointerEvents: 'none',
                      zIndex: 10
                    }}/>
                  )}

                  {/* Correct Location Circle - Moved here */}
                  {showCorrectLocation && (
                    <div style={{
                      position: 'absolute',
                      left: `${streetmoji.emojiX * 100}%`,
                      top: `${streetmoji.emojiY * 100}%`,
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      backgroundColor: 'transparent',
                      border: '3px solid #4CAF50',
                      transform: 'translate(-50%, -50%)',
                      boxShadow: '0 0 20px rgba(0,0,0,0.3)',
                      zIndex: 1000,
                      animation: 'fadeIn 0.5s ease-out',
                      pointerEvents: 'none'
                    }}/>
                  )}
                </div>
              </div>
            </div>

            {/* Show correct location after completion */}
            {isComplete && (
              <div style={{
                position: 'absolute',
                left: `${streetmoji.emojiX * 100}%`,
                top: `${streetmoji.emojiY * 100}%`,
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                border: '3px solid #4CAF50',
                transform: 'translate(-50%, -50%)',
                boxShadow: '0 0 20px rgba(0,0,0,0.3)',
                zIndex: 1000,
                animation: 'fadeIn 0.5s ease-out',
                pointerEvents: 'none'
              }}/>
            )}

            {/* Penalty Notification */}
            {showPenalty && (
              <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                color: 'white',
                padding: '20px 40px',
                borderRadius: '12px',
                fontSize: '24px',
                fontWeight: 'bold',
                animation: 'fadeInOut 2s ease-in-out',
                zIndex: 1000
              }}>
                +10 seconds penalty
              </div>
            )}

            {/* Failure Animation */}
            {showFailureAnimation && (
              <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.5)'
              }}>
                <X 
                  className="failure-cross"
                  size={120}
                  color="red"
                  strokeWidth={4}
                />
              </div>
            )}
          </div>
        )}
      </>
    )}
  </>
);
}
export default StreetmojiPlay; 