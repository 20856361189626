import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { Phone, Mail, Instagram, Twitter, Music2, CreditCard, ChevronLeft } from 'lucide-react';

function PerformerProfile() {
  const { performerId } = useParams();
  const navigate = useNavigate();
  const [performer, setPerformer] = useState(null);
  const [events, setEvents] = useState([]);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchPerformer = async () => {
      console.log('Fetching performer with ID:', performerId);
      const docRef = doc(db, 'Users', performerId);
      const docSnap = await getDoc(docRef);

      console.log('Performer doc exists:', docSnap.exists());
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log('Performer data:', data);
        setPerformer({
          id: docSnap.id,
          ...data,
          profileImage: data.profilePictureURL,
          handle: data.handle,
          bio: data.description,
          mojo: data.mojoPoints,
          links: data.links || [],
          announcement: data.announcement,
        });
      } else {
        console.log('No performer found with ID:', performerId);
      }
    };

    const fetchEvents = async () => {
      console.log('Fetching events for performer:', performerId);
      const eventsRef = collection(db, `Users/${performerId}/performances`);
      const eventsSnap = await getDocs(eventsRef);

      const allEvents = eventsSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log('Found events:', allEvents.length);
      setEvents(allEvents);
    };

    fetchPerformer();
    fetchEvents();
  }, [performerId]);

  const getPlatformIcon = (platform) => {
    switch (platform.toLowerCase()) {
      case 'phone': return <Phone size={20} />;
      case 'email': return <Mail size={20} />;
      case 'instagram': return <Instagram size={20} />;
      case 'x': return <Twitter size={20} />;
      case 'soundcloud': return <Music2 size={20} />;
      case 'cashapp': return <CreditCard size={20} />;
      default: return null;
    }
  };

  // Filter events into three groups: current (happening now), upcoming, and past.
  const filterEvents = (events) => {
    const now = Timestamp.now();
    const current = events.filter(event => 
      event.startDate <= now && event.endDate > now
    );
    const upcoming = events.filter(event => event.startDate > now);
    const past = events.filter(event => event.endDate <= now);
    return { current, upcoming, past };
  };

  const sortEvents = (events) => {
    return [...events].sort((a, b) => b.startDate - a.startDate);
  };

  if (!performer) {
    return (
      <div style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '12px'
      }}>
        <div>Loading performer profile...</div>
        <div style={{ color: '#666', fontSize: '14px' }}>ID: {performerId}</div>
      </div>
    );
  }

  const LinksGrid = () => (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '8px'
    }}>
      {performer.links?.map((link, index) => (
        <a
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '12px',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            color: '#000',
            textDecoration: 'none',
            transition: 'background-color 0.2s',
            fontSize: '14px'
          }}
        >
          {getPlatformIcon(link.platform)}
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {link.platform}
          </span>
        </a>
      ))}
    </div>
  );

  const EventItem = ({ event }) => {
    const navigate = useNavigate();
    const now = Timestamp.now();
    const eventDate = event.startDate.toDate();
    const isThisWeek = (
      eventDate > now.toDate() - 7 * 24 * 60 * 60 * 1000 &&
      eventDate < now.toDate() + 7 * 24 * 60 * 60 * 1000
    );

    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    };

    const formatDate = (date) => {
      if (isThisWeek) {
        return date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
      }
      return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit'
      });
    };

    return (
      <div 
        onClick={() => navigate(`/performance/${event.id}`)}
        style={{
          display: 'flex',
          gap: '16px',
          borderRadius: '12px',
          padding: '16px',
          marginBottom: '20px',
          cursor: 'pointer',
          transition: 'background-color 0.2s'
        }}
      >
        {/* Left side - small event image */}
        <div style={{
          width: '70px',
          height: '70px',
          borderRadius: '8px',
          overflow: 'hidden',
          flexShrink: 0
        }}>
          <img
            src={event.imageURL}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </div>

        {/* Event details */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minWidth: 0,
          height: '70px',
          position: 'relative',
          top: '-20px'
        }}>
          {/* Top line: Name and Type */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            marginBottom: '4px'
          }}>
            <h3 style={{ 
              fontSize: '22px',
              fontWeight: '600',
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {event.performanceName}
            </h3>
            <span style={{
              fontSize: '12px',
              color: '#666',
              textTransform: 'none'
            }}>
              {event.genre}
            </span>
          </div>

          {/* Bottom line: Location and Time */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#666',
            fontSize: '14px',
            marginTop: '-10px'
          }}>
            <span style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {event.location}
            </span>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              flexShrink: 0
            }}>
              <span>{formatTime(event.startDate.toDate())} - {formatTime(event.endDate.toDate())}</span>
              <span>•</span>
              <span>{formatDate(event.startDate.toDate())}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Desktop Layout
  const DesktopLayout = () => {
    const filtered = filterEvents(events);
    const upcomingEvents = sortEvents(filtered.upcoming);
    const pastEvents = sortEvents(filtered.past);
    const currentEvents = sortEvents(filtered.current);

    return (
      <>
       <div
        style={{
          position: 'fixed',
          top: '10px',
          left: '20px',
          zIndex: 1001,
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(10px)',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}
        onClick={() => navigate(-1)}
      >
        <ChevronLeft color="white" size={24} />
      </div>
        {/* Fixed Announcement Banner */}
        {performer.announcement && (
          
          <div 
          className="electronic"
          style={{
            position: 'fixed',
            top: 0,  backgroundColor: 'rgb(0,0,0,.8)',
            color: 'orange',
            left: 0,
            right: 0,
            padding: '20px',
            zIndex: 1000,
            textAlign: 'center',
            
            backdropFilter: 'blur(10px)',
          }}>
            {performer.announcement}
          </div>
        )}

        {/* Main Content with Fixed Left Column */}
        <div style={{
          display: 'flex',
          minHeight: '100vh',
          paddingTop: performer.announcement ? '60px' : '0px'
        }}>
          {/* Fixed Left Column */}
          <div style={{
            position: 'fixed',
            top: performer.announcement ? '60px' : '0',
            left: 0,
            bottom: 0,
            width: '350px',
            backgroundColor: '#1651d4',
            padding: '24px',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px'
          }}>
            {/* Profile Image */}
            <div style={{
              width: '100%',
              aspectRatio: '1',
              borderRadius: '16px',
              overflow: 'hidden',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
            }}>
              <img 
                src={performer.profileImage} 
                alt={performer.handle}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </div>

            {/* Mojo and Handle */}
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '16px'
            }}>
              <span style={{ 
                fontSize: '20px',
                color: 'rgba(255,255,255,0.8)',
                fontWeight: 'normal'
              }}>
                {performer.handle}
              </span>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'rgba(255,255,255,0.8)'
              }}>
                <span style={{ 
                  fontSize: '20px',
                  fontWeight: 'normal'
                }}>
                  {performer.mojo || 0}
                </span>
                <span style={{
                  fontSize: '16px',
                  fontWeight: 'normal'
                }}>
                  mojo
                </span>
              </div>
            </div>

            {/* Bio */}
            <p style={{ 
              color: 'rgba(255,255,255,0.8)',
              lineHeight: 1.6,
              fontSize: '16px'
            }}>
              {performer.bio}
            </p>

            {/* Links */}
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '8px'
            }}>
              {performer.links?.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '12px',
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    borderRadius: '8px',
                    color: 'white',
                    textDecoration: 'none',
                    transition: 'background-color 0.2s',
                    fontSize: '14px'
                  }}
                >
                  {getPlatformIcon(link.platform)}
                  <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {link.platform}
                  </span>
                </a>
              ))}
            </div>
          </div>

          {/* Right Column */}
          <div style={{
            marginLeft: '350px',
            flex: 1,
            padding: '40px 80px',
            maxWidth: '1000px'
          }}>
            {/* Happening Now Section */}
            {currentEvents.length > 0 && (
              <div style={{
                backgroundColor: 'rgba(22, 81, 212, 0.05)',
                padding: '0px',
                borderRadius: '12px',
                marginBottom: '32px'
              }}>
                <h3 style={{
                  fontSize: '16px',
                  marginBottom: '12px',
                  color: '#1651d4',
                  fontWeight: 'normal'
                }}>
                  
                </h3>
                {currentEvents.map(event => (
                  <EventItem key={event.id} event={event} />
                ))}
              </div>
            )}

            {/* Tabs for Upcoming / Past */}
            <div style={{
              display: 'flex',
              gap: '16px',
              position: 'sticky',
              top: '60px',
              background: 'rgb(255,255,255,.95)',
              backdropFilter: 'blur(10px)',
               zIndex: '100',
              padding: '30px 20px',
              marginBottom: '24px'
            }}>
              {['upcoming', 'past'].map(tab => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  style={{
                    marginRight: '20px',
                    padding: '8px 0px',   border: 'none',
                    borderBottom: activeTab === tab ? '3px solid black' : '2px solid transparent',
                    backgroundColor: 'transparent',
                    fontWeight: '600',
                    cursor: 'pointer',
                    fontSize: '16px',
                    transition: 'all 0.2s ease'
                  }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)} Events
                </button>
              ))}
            </div>

            {/* Tab Content */}
            {activeTab === 'upcoming' ? (
              upcomingEvents.length > 0 ? (
                upcomingEvents.map(event => (
                  <EventItem key={event.id} event={event} />
                ))
              ) : (
                <div style={{
                  textAlign: 'center',
                  padding: '40px',
                  color: '#666',
                  fontSize: '16px'
                }}>
                  No upcoming events
                </div>
              )
            ) : (
              pastEvents.length > 0 ? (
                pastEvents.map(event => (
                  <EventItem key={event.id} event={event} />
                ))
              ) : (
                <div style={{
                  textAlign: 'center',
                  padding: '40px',
                  color: '#666',
                  fontSize: '16px'
                }}>
                  No past events
                </div>
              )
            )}
          </div>
        </div>
      </>
    );
  };

  // Mobile Layout
  const MobileLayout = () => {
    const filtered = filterEvents(events);
    const upcomingEvents = sortEvents(filtered.upcoming);
    const pastEvents = sortEvents(filtered.past);
    const currentEvents = sortEvents(filtered.current);

    return (
      <div>
        {/* Announcement Banner */}
        {performer.announcement && (
          <div 
           className="electronic"
          style={{
            backgroundColor: 'rgb(0,0,0,.8)',
            color: 'orange',
            padding: '16px',
            marginBottom: '20px'
          }}>
            {performer.announcement}
          </div>
        )}

        {/* Profile Section */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}>
          {/* Full-width Profile Image */}
          <div style={{
            width: '100vw',
            aspectRatio: '1',
            marginLeft: '-20px',
            marginRight: '-20px'
          }}>
            <img 
              src={performer.profileImage} 
              alt={performer.handle}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </div>

          {/* Content with Padding */}
          <div style={{ padding: '0 20px' }}>
            {/* Mojo and Handle */}
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '16px'
            }}>
              <span style={{ 
                fontSize: '20px',
                color: '#666',
                fontWeight: 'normal'
              }}>
                {performer.handle}
              </span>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: '#666'
              }}>
                <span style={{ 
                  fontSize: '20px',
                  fontWeight: 'normal'
                }}>
                  {performer.mojo || 0}
                </span>
                <span style={{
                  fontSize: '16px',
                  fontWeight: 'normal'
                }}>
                  mojo
                </span>
              </div>
            </div>

            {/* Bio */}
            <p style={{ 
              color: '#666',
              lineHeight: 1.6,
              fontSize: '16px'
            }}>
              {performer.bio}
            </p>

            {/* Links */}
            <LinksGrid />

            {/* Happening Now Section */}
            {currentEvents.length > 0 && (
              <div style={{
                backgroundColor: 'rgba(22, 81, 212, 0.05)',
                padding: '20px',
                borderRadius: '12px',
                marginBottom: '32px'
              }}>
                <h3 style={{
                  fontSize: '16px',
                  marginBottom: '12px',
                  color: '#1651d4',
                  fontWeight: 'normal'
                }}>
                  Happening Now
                </h3>
                {currentEvents.map(event => (
                  <EventItem key={event.id} event={event} />
                ))}
              </div>
            )}

            {/* Tabs */}
            <div style={{
              display: 'flex',
              gap: '12px',
              marginTop: '12px'
            }}>
              {['upcoming', 'past'].map(tab => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  style={{
                    flex: 1,
                    padding: '8px',
                    borderBottom: activeTab === tab ? '2px solid #000' : '2px solid transparent',
                    backgroundColor: 'transparent',
                    color: '#000',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            {/* Tab Content */}
            <div style={{ marginTop: '20px' }}>
              {activeTab === 'upcoming' ? (
                upcomingEvents.length > 0 ? (
                  upcomingEvents.map(event => (
                    <EventItem key={event.id} event={event} />
                  ))
                ) : (
                  <div style={{
                    textAlign: 'center',
                    padding: '40px',
                    color: '#666',
                    fontSize: '16px'
                  }}>
                    No upcoming events
                  </div>
                )
              ) : (
                pastEvents.length > 0 ? (
                  pastEvents.map(event => (
                    <EventItem key={event.id} event={event} />
                  ))
                ) : (
                  <div style={{
                    textAlign: 'center',
                    padding: '40px',
                    color: '#666',
                    fontSize: '16px'
                  }}>
                    No past events
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return isMobile ? <MobileLayout /> : <DesktopLayout />;
}

export default PerformerProfile;
