import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Heart, ChevronLeft, ChevronRight, MapPin } from 'lucide-react';

function PerformanceDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchEvent = async () => {
      const docRef = doc(db, 'events', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setEvent({ id: docSnap.id, ...docSnap.data() });
      }
    };
    fetchEvent();

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [id]);

  if (!event) return null;

  const formatTime = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const weekFromNow = new Date(today);
    weekFromNow.setDate(weekFromNow.getDate() + 7);

    if (date.toDateString() === today.toDateString()) {
      return 'TODAY';
    }
    if (date.toDateString() === tomorrow.toDateString()) {
      return 'TOMORROW';
    }
    if (date < weekFromNow) {
      return date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
    }
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
  };

  const MobileView = () => (
    <div style={{ height: '100vh', overflowY: 'auto' }}>
      {/* Top Image Section */}
      <div style={{ position: 'relative', width: '100%', paddingTop: '100%' }}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${event.imageURL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }} />
        
        {/* Top Bar */}
        <div style={{
          position: 'absolute',
          top: 20,
          left: 20,
          right: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 2
        }}>
          <button
            onClick={() => navigate(-1)}
            style={{
              background: 'rgba(0, 0, 0, 0.6)',
              backdropFilter: 'blur(10px)',
              border: 'none',
              borderRadius: '50%',
              padding: '10px',
              cursor: 'pointer'
            }}
          >
            <ChevronLeft color="white" size={24} />
          </button>
          <div style={{
            background: 'rgba(0, 0, 0, 0.6)',
            backdropFilter: 'blur(10px)',
            padding: '8px 16px',
            borderRadius: '20px',
            color: '#FF8A00',
            fontSize: '14px'
          }} className="electronic">
            {formatTime(event.startTime)} - {formatTime(event.endTime)}
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div style={{ padding: '20px' }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          marginBottom: '16px'
        }}>
          <div style={{ flex: 1 }}>
            <h1 style={{ margin: '0 0 8px 0', fontSize: '24px' }}>{event.name}</h1>
            <p style={{ margin: '0', color: '#666', fontSize: '16px' }}>{event.location}</p>
          </div>
          <button
            onClick={() => setIsLiked(!isLiked)}
            style={{
              background: 'none',
              border: 'none',
              padding: '8px',
              cursor: 'pointer'
            }}
          >
            <Heart 
              size={24} 
              fill={isLiked ? '#ff4b7d' : 'none'}
              color={isLiked ? '#ff4b7d' : '#ff4b7d'}
            />
          </button>
        </div>

        <div style={{ marginBottom: '24px' }}>
          <div style={{
            display: 'flex',
            gap: '8px',
            fontSize: '14px',
            color: '#666',
            marginBottom: '16px'
          }}>
            <span>{event.genre}</span>
            •
            <span>{event.category}</span>
          </div>
          <p style={{ margin: '0', fontSize: '16px', lineHeight: 1.5 }}>
            {event.description}
          </p>
        </div>

        {/* Links Section - Updated to 2 columns */}
        <div style={{ 
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '12px',
          marginBottom: '24px'
        }}>
          {event.links?.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                padding: '12px',
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                textDecoration: 'none',
                color: '#000',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '14px'
              }}
            >
              {link.platform}
            </a>
          ))}
        </div>

        {/* Added Performer Info */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          padding: '16px',
          borderRadius: '12px',
          marginTop: 'auto'
        }}>
          <img 
            src={event.performerProfilePictureURL}
            alt={event.performerName}
            style={{
              width: '60px',
              height: '60px',
              borderRadius: '8px',
              objectFit: 'cover'
            }}
          />
          <div>
            <p style={{
              fontSize: '18px',
              fontWeight: 'bold',
              margin: '0 0 4px 0'
            }}>
              {event.performerName}
            </p>
            <div 
              onClick={() => navigate(`/performer/${event.performerId}`)}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                fontSize: '14px',
                color: '#666',
                cursor: 'pointer'
              }}
            >
              <span>See what else I have coming up</span>
              <ChevronRight size={14} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const WebView = () => (
    <div style={{
      height: '100vh',
      display: 'flex',
      background: '#1651d4',
      position: 'relative',
      overflow: 'hidden'
    }}>
      {/* Content Section - Left */}
      <div style={{
        flex: '0 0 30%',
        padding: '40px 60px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 2
      }}>
        <button
          onClick={() => navigate(-1)}
          style={{
            position: 'absolute',
            top: 40,
            left: 40,
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            color: 'white',
            padding: 0
          }}
        >
          <ChevronLeft size={24} />
        </button>

        <h1 style={{ 
          fontSize: '48px', 
          margin: '0 0 24px 0'
        }}>
          {event.name}
        </h1>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '12px'
        }}>
          <MapPin size={20} style={{ opacity: 0.8 }} />
          <p style={{ 
            fontSize: '24px', 
            opacity: 0.9,
            margin: 0
          }}>
            {event.location}
          </p>
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          fontSize: '14px',
          opacity: 0.8,
          color: 'white',
          padding: '4px 12px',
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '20px',
          whiteSpace: 'nowrap',
          marginBottom: '40px',
          alignSelf: 'flex-start'
        }}>
          <span>{event.genre}</span>
          •
          <span>{event.category}</span>
        </div>

        <p style={{
          fontSize: '18px',
          lineHeight: 1.6,
          marginBottom: '40px',
          opacity: 0.9
        }}>
          {event.description}
        </p>

        {/* Performer Info */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          marginBottom: '40px'
        }}>
          <img 
            src={event.performerProfilePictureURL}
            alt={event.performerName}
            style={{
              width: '80px',
              height: '80px',
              borderRadius: '12px',
              objectFit: 'cover'
            }}
          />
          <div>
            <p style={{
              fontSize: '22px',
              fontWeight: 'bold',
              margin: '0 0 8px 0'
            }}>
              {event.performerName}
            </p>
            <div 
              onClick={() => navigate(`/performer/${event.performerId}`)}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '16px',
                opacity: 0.8,
                cursor: 'pointer'
              }}
            >
              <span>See what else I have coming up</span>
              <ChevronRight size={16} />
            </div>
          </div>
        </div>

        {/* Links Section */}
        <h3 style={{
          fontSize: '16px',
          fontWeight: 'normal',
          margin: '0 0 16px 0',
          opacity: 0.8
        }}>
          Check out my links
        </h3>

        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '12px'
        }}>
          {event.links?.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                padding: '12px 20px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '8px',
                textDecoration: 'none',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '14px',
                transition: 'background-color 0.2s',
                ':hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)'
                }
              }}
            >
              {link.platform}
            </a>
          ))}
        </div>
      </div>

      {/* Right Section */}
      <div style={{
        flex: '0 0 70%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex'
      }}>
        {/* Blurred Background */}
        <div style={{
          position: 'absolute',
          top: -20,
          left: -20,
          right: -20,
          bottom: -20,
          backgroundImage: `url(${event.imageURL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(20px)',
          opacity: 0.5
        }} />

        {/* Main Image */}
        <div style={{
          position: 'relative',
          width: '100%',
          backgroundImage: `url(${event.imageURL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 1
        }}>
          {/* Date Indicator */}
          <div style={{
            position: 'absolute',
            top: 40,
            left: 40,
            padding: '12px 16px',
            background: 'rgba(0, 0, 0, 0.7)',
            backdropFilter: 'blur(10px)',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            maxWidth: 'fit-content',
            zIndex: 2
          }} className="electronic">
            <span style={{
              color: '#FF8A00',
              fontSize: '16px',
              letterSpacing: '1px'
            }}>
              {formatDate(event.startTime)}
            </span>
            <span style={{
              color: '#FF8A00',
              fontSize: '16px'
            }}>
              {formatTime(event.startTime)} - {formatTime(event.endTime)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return isMobile ? <MobileView /> : <WebView />;
}

export default PerformanceDetail; 