import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PerformanceDetail from './pages/PerformanceDetail';
import PerformerScan from './pages/PerformerScan';
import DeveloperStreetmoji from './pages/DeveloperStreetmoji';
import StreetmojiPlay from './pages/StreetmojiPlay';
import StreetmojiDeveloper from './pages/StreetmojiDeveloper';
import PerformerProfile from './pages/performer/[id]';
import StreetmojiResults from './pages/StreetmojiResults';
import Schmetterling from './pages/Schmetterling';
import Legal from './pages/Legal';
import BinaryConverter from './pages/BinaryConverter';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/performance/:id" element={<PerformanceDetail />} />
        <Route path="/performer-scan" element={<PerformerScan />} />
        <Route path="/developer-streetmoji" element={<DeveloperStreetmoji />} />
        <Route path="/streetmoji" element={<StreetmojiPlay />} />
        <Route path="/streetmoji/developer" element={<StreetmojiDeveloper />} />
        <Route path="/performer/:performerId" element={<PerformerProfile />} />
        <Route path="/scan/:performerId" element={<PerformerScan />} />
        <Route path="/streetmoji/results/:date/:time/:success/:streak/:emoji" element={<StreetmojiResults />} />
        <Route path="/schmetterling" element={<Schmetterling />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/binary" element={<BinaryConverter />} />
      </Routes>
    </Router>
  );
}

export default App;
