import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Change to arrow function and explicit export
const MetaTags = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [emoji, setEmoji] = useState(null);

  useEffect(() => {
    const fetchTodaysStreetmoji = async () => {
      const today = new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).replace(/\//g, '');
      
      try {
        const docRef = doc(db, 'streetmojiweb', today);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          setImageUrl(data.imageURL);
          setEmoji(data.emoji);
        }
      } catch (error) {
        console.error('Error fetching Streetmoji:', error);
      }
    };

    fetchTodaysStreetmoji();
  }, []);

  if (!emoji || !imageUrl) return null;

  return (
    <Helmet>
      <title>{`Play Streetmoji - Find the ${emoji}`}</title>
      
      {/* Primary Meta Tags */}
      <meta name="description" content="Find hidden emojis on the street. A new challenge every day!" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://ondast.web.app/streetmoji" />
      <meta property="og:title" content={`Play Streetmoji - Find the ${emoji}`} />
      <meta property="og:description" content="Find hidden emojis on the street. A new challenge every day!" />
      <meta property="og:site_name" content="Onda St." />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={`Today's Streetmoji Challenge - Find the ${emoji}`} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ondast" />
      <meta name="twitter:title" content={`Play Streetmoji - Find the ${emoji}`} />
      <meta name="twitter:description" content="Find hidden emojis on the street. A new challenge every day!" />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

// Make sure to export default
export default MetaTags; 