import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBThEzhjjbeoIxbb_8jnfPNNdgd7PuabW0",
  authDomain: "ondast-app.firebaseapp.com",
  projectId: "ondast-app",
  storageBucket: "ondast-app.firebasestorage.app",
  messagingSenderId: "948208816673",
  appId: "1:948208816673:web:0a1ab448eea5ce941d8960",
  measurementId: "G-HTHMWEVTKK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage }; 